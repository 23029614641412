/* eslint-disable max-len */
/* eslint-disable no-console */
import axios from 'axios';

export const useSubmitForm = (body, formId, callback, fallback) => {
  const parameters = [];
  Object.keys(body).forEach((key) => {
    const parameter = {
      name: key,
      value: body[key] === true ? 'true' : body[key]
    };
    parameters.push(parameter);
  });
  parameters.push({
    name: 'invia',
    value: 'invia'
  });
  const formattedBody = {
    idform: formId,
    language: 'ITA',
    parameters
  };
  let baseUrl = '';
  if (typeof window !== 'undefined') {
    baseUrl = window.location.origin;
  }
  axios.post(`${baseUrl}/.netlify/functions/submit-form`, formattedBody)
    .then((res) => {
      if (res.data.status === 'OK') {
        callback();
      }
      if (res.data.status === 'Error') {
        fallback();
      }
    })
    .catch((err) => {
      console.log(err);
      fallback();
    });
};
