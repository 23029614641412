import React, { useState } from 'react';
import Img from 'gatsby-image';
import Container from '../Container';
import PeopleModal from '../PeopleModal';

const PeopleList = ({
  marginTop, marginBottom, title, content, people
}) => {
  const [modal, setModal] = useState(-1);

  const closeModal = () => {
    setModal(-1);
  };

  return (
    <Container
      marginTop={marginTop}
      marginBottom={marginBottom}
      className="people-list"
    >
      <div className="people-list__wrapper">
        {title && <div className="people-list__title text-color-brand label">{title}</div>}
        {
          content && (
            <div
              className="people-list__content paragraph"
              dangerouslySetInnerHTML={{ __html: `${content}` }}
            />
          )
        }
        <div className="people-list__container">
          {
            people && people.map((person, index) => {
              const {
                image, role, name, biography, isCircleImage
              } = person;
              if (biography) {
                return (
                  <div key={`${person.id}-${index}`} className="person">
                    <button type="button" className="person__btn" onClick={() => setModal(index)}>
                      {
                        image && (
                          <Img
                            fluid={image.fluid}
                            className={`person__image ${isCircleImage ? 'is-circle' : ''}`}
                            alt={image.alt}
                          />
                        )
                      }

                      <ul className="person__information">
                        <li className="person__item paragraph text-color-brand">{name}</li>
                        {role && (
                          <li className="person__item paragraph">{role}</li>
                        )}
                      </ul>
                    </button>
                  </div>
                );
              }
              return (
                <div key={`${person.id}-${index}`} className="person">
                  <div className="person__container">
                    {image && (
                      <Img
                        fluid={image.fluid}
                        className={`person__image ${isCircleImage ? 'is-circle' : ''}`}
                        alt={image.alt}
                      />
                    )}
                    <ul className="person__information">
                      <li className="person__item paragraph text-color-brand">{name}</li>
                      {role && <li className="person__item paragraph">{role}</li>}
                    </ul>
                  </div>
                </div>
              );
            })
          }
        </div>
        <PeopleModal people={people} closeModal={closeModal} modal={modal} />
      </div>
    </Container>
  );
};

export default PeopleList;
