import React, { useState } from 'react';
import Container from '../Container';
import PeopleModal from '../PeopleModal';

const ListPartners = ({
  marginTop, marginBottom, partners
}) => {
  const [modal, setModal] = useState(-1);

  const closeModal = () => {
    setModal(-1);
  };

  return (
    <Container
      className="list-partners"
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      <div className="list-partners__grid">
        <div className="list-partners-wrapper">
          {
            partners && partners.map((partner, index) => {
              const { id, image, title } = partner;
              return (
                <button className="list-partners__card" type="button" onClick={() => setModal(index)} key={id}>
                  <figure className="list-partners__container-image">
                    <img
                      className="list-partners__image"
                      src={image.url}
                      alt={image.alt}
                    />
                  </figure>
                  {title && <h4 className="list-partners__title label-small">{title}</h4>}
                </button>
              );
            })
          }
        </div>
        <PeopleModal
          people={partners}
          hasLogos
          closeModal={closeModal}
          modal={modal}
        />
      </div>
    </Container>
  );
};
export default ListPartners;
