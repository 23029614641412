import React from 'react';
import Img from 'gatsby-image';
import Container from '../Container';
import { ButtonLink } from '../Link';
import { useCreateSourceHeaderImage } from '../../hooks/archive-hooks';

const BannerFullImage = ({
  colorTheme, content, image, imageMobile, url, marginTop, marginBottom, label, labelButton
}) => {
  const dictionary = {
    giallo: ['1', 'yellow-on-black'],
    azzurro: ['2', 'brand-on-black'],
  };

  const theme = dictionary[colorTheme];

  return (
    <Container
      className={`banner-full-image banner-full-image--theme-v${theme[0]}`}
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      {image && (
        <div className="banner-full-image__image-container">
          <Img
            fluid={useCreateSourceHeaderImage(image, imageMobile)}
            className="banner-full-image__image"
            alt={image.alt}
          />
        </div>
      )}

      <div className="banner-full-image__wrapper">
        <div className="banner-full-image__content-wrapper">
          {label && <span className="banner-full-image__title label-small">{label}</span>}
          {content && <h3 className="banner-full-image__text-content heading-md">{content}</h3>}
          {
            (url && labelButton) && (
              <ButtonLink
                kind="primary"
                label={labelButton}
                color={theme[1]}
                url={url}
                className="banner-full-image__btn"
              />
            )
          }
        </div>
      </div>
    </Container>
  );
};

BannerFullImage.defaultProps = {
  label: 'HELP',
  content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore ',
  labelButton: 'Scopri di più',
  image: {
    fluid: {
      aspectRatio: 1.7777777777777777,
      base64: 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/2wEEEAAJAAsACwANABEADQASABQAFAASABgAGgAYABoAGAAkACEAHgAeACEAJAA2ACcAKQAnACkAJwA2AFEAMwA7ADMAMwA7ADMAUQBHAFYARwBCAEcAVgBHAIAAZQBZAFkAZQCAAJQAfAB2AHwAlACzAKAAoACzAOEA1QDhASUBJQGKEQAJAAsACwANABEADQASABQAFAASABgAGgAYABoAGAAkACEAHgAeACEAJAA2ACcAKQAnACkAJwA2AFEAMwA7ADMAMwA7ADMAUQBHAFYARwBCAEcAVgBHAIAAZQBZAFkAZQCAAJQAfAB2AHwAlACzAKAAoACzAOEA1QDhASUBJQGK/8IAEQgAEAAcAwEiAAIRAQMRAf/EABcAAAMBAAAAAAAAAAAAAAAAAAIFBgT/2gAIAQEAAAAAM0iit3nM/wD/xAAVAQEBAAAAAAAAAAAAAAAAAAABA//aAAgBAhAAAACR/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQT/2gAIAQMQAAAArP/EACcQAAEDAgUDBQEAAAAAAAAAAAECAxEABAUSITFRExRBIjJCYbHh/9oACAEBAAE/AMZw1hzELb0lbeYFQSSTB1McVdWdohgNMoQQYWNIJJ2/tN4YgGemuElQVJB186D9q8S9avFDSlBJAVAG0jz903au9q4EOEFMgRPsG0DzVpa3dwlxXcbEJEpGmm44q1U5HTU4JAVlWNt/nHNXGBMuPOKzuKJVqrNGY81//8QAGREBAAMBAQAAAAAAAAAAAAAAAgABIUGB/9oACAECAQE/AEVvNlBWvJ//xAAdEQEAAQQDAQAAAAAAAAAAAAABAgADESESFEFh/9oACAEDAQE/ALcrGlFQ+VPqFvjjIS2J7X//2Q==',
      sizes: '(max-width: 800px) 100vw, 800px',
      src: 'https://www.datocms-assets.com/30196/1595410080-bannersmall.jpg?auto=compress%2Cformat&dpr=2&fm=jpg'
    }
  },
};

export default BannerFullImage;
