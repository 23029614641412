import React from 'react';
import Container from '../Container';

const Mappa = ({
  marginTop,
  marginBottom,
  src
}) => {
  return (
    <Container
      marginTop={marginTop}
      marginBottom={marginBottom}
      className="mappa"
    >
      <iframe
        title="Mappa Italia Amica"
        src={src}
        className="mappa__wrapper"
      />
    </Container>
  );
};

export default Mappa;
