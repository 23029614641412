import React from 'react';
import Img from 'gatsby-image';
import Container from '../Container';
import Linked from '../Linked';
import { ButtonLink } from '../Link';

// TODO: ask to Valerio if logic for dictionary (colorTheme e button) is ok
const BannerBigImage = ({
  marginTop, marginBottom, image, label, title, description, url,
  labelButton, imagePosition, colorTheme
}) => {
  const dictionaryTheme = {
    bianco: ['1', 'brand'],
    azzurro: ['2', 'white'],
  };

  const dictionaryPosition = {
    sinistra: 'sx',
    destra: 'dx',
  };

  const theme = dictionaryTheme[colorTheme];
  const position = dictionaryPosition[imagePosition];

  return (
    <Container
      marginTop={marginTop}
      marginBottom={marginBottom}
      className={`banner-big-image banner-big-image--theme-v${theme[0]} banner-big-image--position-${position}`}
    >
      <div className="banner-big-image__cards-container">
        {
          image && (
            <Linked
              url={url}
              className="banner-big-image__link"
            >
              <figure className="banner-big-image__image-container">
                <Img
                  fluid={{
                    ...image.fluid,
                    aspectRatio: 16 / 9
                  }}
                  alt={image.alt}
                  className="banner-big-image__image"
                />
              </figure>
            </Linked>
          )
        }
        <div className="banner-big-image__text-container">
          {label && <span className="banner-big-image__label label-small">{label}</span>}
          {title && <h2 className="banner-big-image__title heading-md">{title}</h2>}
          {description && <p className="banner-big-image__description paragraph-small">{description}</p>}
          <ButtonLink
            label={labelButton}
            color={theme[1]}
            kind="primary"
            url={url}
          />
        </div>
      </div>
    </Container>
  );
};

export default BannerBigImage;
