import React from 'react';
import Container from '../Container';
import Linked from '../Linked';

const BannerWithIcons = ({
  marginTop, marginBottom, cards
}) => {
  return (
    <Container
      className="icons-banner"
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      <div className="icons-banner__wrapper">
        <div className="icons-banner__container">
          {
            cards && cards.map((card) => {
              const {
                icon, title, description, url
              } = card;
              if (url) {
                return (
                  <Linked key={card.id} url={url} className="icon-card__container">
                    <figure className="icon-card__figure">
                      <img src={icon.url} className="icon-card__image" alt={icon.alt} />
                    </figure>
                    <span className="icon-card__title label">{title}</span>
                    <p className="icon-card__description caption">{description}</p>
                  </Linked>
                );
              }
              return (
                <div key={card.id} className="icon-card__container">
                  <figure className="icon-card__figure">
                    <img src={icon.url} className="icon-card__image" alt={icon.alt} />
                  </figure>
                  <span className="icon-card__title label">{title}</span>
                  <p className="icon-card__description caption">{description}</p>
                </div>
              );
            })
          }
        </div>
      </div>
    </Container>
  );
};

export default BannerWithIcons;
