import React, { useEffect } from 'react';
import Container from '../Container';

const DataVisualization = ({
  marginTop, marginBottom, idInfogram, title
}) => {
  useEffect(() => {
    /* eslint-disable */
    !function(e,i,n,s) {
      var t="InfogramEmbeds",
      d=e.getElementsByTagName("script")[0];
      console.log(e, i, n, s, window);
      if(window[t] && window[t].initialized) {
        window[t].process&&window[t].process();
      }
      else if(!e.getElementById(n)) {
        var o=e.createElement("script");
        o.async=1,
        o.id=n,
        o.src="https://e.infogram.com/js/dist/embed-loader-min.js",
        d.parentNode.insertBefore(o,d)
      }
    } (document,0,"infogram-async");
  }, [])
  return (
    <Container
      marginTop={marginTop}
      marginBottom={marginBottom}
      className="data-visualization"
    >
      <div className="data-visualization__wrapper">
        <div className="infogram-embed data-visualization__embed" data-id={idInfogram} data-type="interactive" data-title={title} />
      </div>
    </Container>
  );
};

export default DataVisualization;
