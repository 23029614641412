import React from 'react';
import Container from '../Container';
import AccordionRichText from '../AccordionRichText';
import withAccordionGroup from '../../hoc/with-accordion-group';

const AccordionGroup = withAccordionGroup(AccordionRichText);

const AccordionWithIntro = ({
  intro, label, title, description, accordions, marginTop, marginBottom
}) => {
  const displayIntro = intro || title || description;
  return (
    <Container
      className="accordion-with-intro"
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      <div className="accordion-with-intro__wrapper">
        {
          displayIntro && (
            <header className="accordion__header">
              {label && <div className="accordion__subtitle label-small">{label}</div>}
              {title && <h3 className="accordion__title-page heading-md">{title}</h3>}
              {description && <div className="accordion__description paragraph-small">{description}</div>}
            </header>
          )
        }
        {
          accordions && (
            <div className={`accordion__list ${intro ? '' : 'accordion__list-no-intro'}`}>
              <AccordionGroup accordions={accordions} />
            </div>
          )
        }
      </div>
    </Container>
  );
};

export default AccordionWithIntro;
