import React from 'react';

const Spacing = ({ backgroundColor, size }) => {
  const sizeDictionary = {
    piccolo: 'small',
    grande: 'big'
  };
  const classes = `
    ${backgroundColor ? `bg-color-${backgroundColor}` : ''}
    ${size !== undefined ? `padding-top-${sizeDictionary[size]}` : ''}`.replace(/\s+/g, ' ');
  return (
    <div className={classes} />
  );
};

export default Spacing;
