import React, { useState, useLayoutEffect, useRef } from 'react';
import Swiper from 'swiper';
import Icon from '../Icons';
import Container from '../Container';
/**
 * Slider used to list witnesses
 */

const swiperOptions = {
  slidesPerView: 'auto',
  spaceBetween: 20,
  grabCursor: true,
  initialSlide: 0,
  preloadImages: false,
  lazy: true,
  loop: true,
  updateOnWindowResize: true,
  loopedSlides: 6,
  loopAdditionalSlides: 60,
  breakpoints: {
    320: {
      slidesPerView: 'auto',
      spaceBetween: 20
    },
    426: {
      spaceBetween: 24
    },
    1024: {
      slidesPerView: 2,
    },
    1366: {
      slidesPerView: 'auto',
    },
    1536: {
      slidesPerView: 3,
    }
  }
};

const SliderTestimonial = ({
  marginTop, marginBottom, label, title, description, testimonials, colorTheme
}) => {
  const [mySwiper, setMySwiper] = useState(null);
  const swiperContainer = useRef(null);
  useLayoutEffect(() => {
    if (swiperContainer.current && mySwiper === null) {
      const swiper = new Swiper(swiperContainer.current, swiperOptions);
      setMySwiper(swiper);
    }
  }, []);

  const slideNext = () => {
    if (mySwiper) {
      mySwiper.slideNext();
    }
  };

  const slidePrev = () => {
    if (mySwiper) {
      mySwiper.slidePrev();
    }
  };

  // TODO: test if color theme change works
  const dictionary = {
    nero: '1',
    grigio: '2',
  };

  const theme = dictionary[colorTheme];

  return (
    <Container
      className={`testimonials testimonials--theme-v${theme}`}
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      <div className="testimonials__container">
        <div className="testimonials__header">
          <div className="testimonials__text-container">
            {label && <span className="label-small text-color-brand">{label}</span>}
            {title && <h3 className="heading-md testimonials__title">{title}</h3>}
            <p className="paragraph-small testimonials__description">
              {description}
            </p>
          </div>
          <div className="testimonials__controllers">
            <button className="testimonials__cta" type="button" onClick={() => slidePrev()}>
              <Icon className="icon--40 testimonials__icon" path="arrow-big-left" />
            </button>
            <button className="testimonials__cta" type="button" onClick={() => slideNext()}>
              <Icon className="icon--40 testimonials__icon" path="arrow-big-right" />
            </button>
          </div>
        </div>
        <div className="testimonials__slider">
          <div ref={swiperContainer} className="swiper-container">
            <div className="swiper-wrapper">
              {testimonials.map((testimonial) => {
                const {
                  id, content, name, company
                } = testimonial;
                return (
                  <div key={id} className="swiper-slide testimonials__slide">
                    <div className="card-text__container">
                      <p className="heading-sm card-text__title">
                        &ldquo;
                        {content}
                        &rdquo;
                      </p>
                      <span className="caption card-text__date">
                        <strong>
                          {name}
                          ,&nbsp;
                        </strong>
                        {company}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default SliderTestimonial;
