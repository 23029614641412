/* eslint-disable */
import React, { useState } from "react"
import { useForm, Controller } from 'react-hook-form';
import TextField from '../TextFieldCustom';
import CheckBox from '../CheckboxCustom';
import Select from '../Select';
import Button from '../Button';
import { useSubmitForm } from '../../hooks/hook.submit-form';

import provinceItaliane from '../../assets/province-italiane.json';

const eta = [...Array(82)].map((eta, index) => {
  return {
    value: `${index + 18}`,
    label: index + 18
  };
});

const FormDiventaVolontario = ({ setShowThankYouPage, disclaimer }) => {
  const {
    handleSubmit,
    register,
    errors,
    control,
    getValues
  } = useForm({
    mode: 'onFocus'
  });
  const atLeastOne = () => {
    return getValues('Area di interesse').length ? true : "Scegli almeno un'area di interesse";
  };

  const [loadingOnSubmit, setLoadingOnSubmit] = useState({
    label: 'Invia',
    disabled: false
  });

  const onSubmit = (data) => {
    setLoadingOnSubmit({
        label: 'Sto Inviando',
        disabled: true
      }
    );
    useSubmitForm(
      data,
      399,
      () => setShowThankYouPage(true),
      () => {
        setLoadingOnSubmit({
            label: 'Invia',
            disabled: false
          }
        );
        alert('Ops, errore di rete, ritenta l\'invio');
      }
    );
  };

  const checkboxGroup = [
    {
      label: 'Iniziative Locali',
      value: 'INIZIATIVE LOCALI'
    },
    {
      label: 'Servizio Civile',
      value: 'SERVIZIO CIVILE'
    },
    {
      label: 'YOUNICEF',
      value: 'YOUNICEF'
    },
    {
      label: 'Web',
      value: 'WEB'
    },
    {
      label: 'Nelle scuole',
      value: 'NELLE SCUOLE'
    },
    {
      label: 'Nelle istituzioni',
      value: 'NELLE ISTITUZIONI'
    },
  ];

  return (
    <form noValidate className="form-group" onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group__container">
        <h3 className="form-group__title heading-md">Diventa volontario</h3>

        {/** NAME */}
        <TextField
          className="form-group__textfield"
          type="text"
          name="Nome"
          label="Nome"
          error={errors['Nome'] ? errors['Nome'].message : ''}
          required
          inputRef={register({
            required: 'Campo obbligatorio',
            minLength: {
              value: 3,
              message: 'Il nome deve essere almeno di 3 cifre',
            }
          })}
        />

        {/** SURNAME */}
        <TextField
          className="form-group__textfield"
          type="text"
          label="Cognome"
          name="Cognome"
          error={errors['Cognome'] ? errors['Cognome'].message : ''}
          required
          inputRef={register({
            required: 'Campo obbligatorio',
            minLength: {
              value: 3,
              message: 'Il cognome deve essere almeno di 3 cifre',
            }
          })}
        />

        {/** TELEPHONE */}
        <TextField
          type="text"
          label="Telefono"
          name="Telefono"
          required
          className="form-group__textfield"
          error={errors['Telefono'] ? errors['Telefono'].message : ''}
          inputRef={register({
            required: 'Campo obbligatorio',
            minLength: {
              value: 5,
              message: 'Il numero deve essere almeno di 5 cifre',
            },
            pattern: {
              value: /[+0-9]+/,
              message: 'Il formato non corrisponde'
            }
          })}
        />

        {/** EMAIL */}
        <TextField
          className="form-group__textfield"
          type="text"
          label="Email"
          name="Email"
          required
          error={errors['Email'] ? errors['Email'].message : ''}
          inputRef={register({
            required: 'Campo obbligatorio',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Formato email non valido'
            }
          })}
        />

        <h4 className="form-group__subtitle heading-md">Dati aggiuntivi/della struttura/del volontario/a</h4>
        {/** DENOMINATION */}
        <TextField
          type="text"
          name="Comune"
          label="Comune"
          className="form-group__textfield"
          required
          error={errors['Comune'] ? errors['Comune'].message : ''}
          inputRef={register({
            required: 'Campo obbligatorio'
          })}
        />

        {/** PROVINCE */}
        <Controller
          control={control}
          name="Provincia_pn"
          rules={{ required: 'Campo obbligatorio' }}
          render={({ onChange }) => (
            <Select
              className="form-group__select"
              label="Provincia"
              name="Provincia_pn"
              options={provinceItaliane}
              handleChange={(value) => onChange(value)}
              error={errors['Provincia_pn'] ? errors['Provincia_pn'].message : ''}
              required
              inputRef={register({
                required: 'Campo obbligatorio'
              })}
            />
          )}
        />

        {/** AGE */}
        <Controller
          control={control}
          name="Eta"
          rules={{ required: 'Campo obbligatorio' }}
          render={({ onChange }) => (
            <Select
              className="form-group__select"
              label="Età"
              name="Eta"
              options={eta}
              handleChange={(value) => onChange(value)}
              error={errors['Eta'] ? errors['Eta'].message : ''}
              required
              inputRef={register({
                required: 'Campo obbligatorio'
              })}
            />
          )}
        />

        <div className="form-group__multiselect">
          <p className={`form-group__title-multiselect paragraph ${errors['Area di interesse'] ? 'title--with-error' : ''}`}>Area di interesse *</p>

          {
            checkboxGroup.map((checkbox) => {
              return (
                <CheckBox
                  key={checkbox.value}
                  variant="white"
                  name="Area di interesse"
                  label={checkbox.label}
                  value={checkbox.value}
                  className="form-group__checkbox"
                  inputRef={register({
                    validate: atLeastOne
                  })}
                />
              );
            })
          }
          {errors['Area di interesse'] && <p className="label-small text-color-orange styled-error">{errors['Area di interesse'].message}</p>}

        </div>

        {/** PRIVACY POLICY */}
        <div className="paragraph-small" dangerouslySetInnerHTML={{__html: disclaimer}} />
        <div className="form-group__privacy">
          {/** SUBMIT BUTTON */}
          <Button type="submit" kind="primary" disabled={loadingOnSubmit.disabled} label={loadingOnSubmit.label} color="brand" />
        </div>
      </div>
    </form>
  );
};

FormDiventaVolontario.defaultProps = {
  disclaimer: '<strong>Informativa ex art. 13, GDPR</strong>\n' +
    '\n' +
    '        <p>Titolare del trattamento dei dati è il Comitato Italiano per l’UNICEF - Fondazione Onlus, via Palestro 68, 00185 Roma (RM). I dati personali saranno trattai per le seguenti finalità :</p>\n' +
    '        <ul>\n' +
    '          <li>Rispondere alla richiesta di contatto formulata dall’utente</li>\n' +
    '          <li>Ottemperare a norme di legge o regolamento</li>\n' +
    '          <li>Fornire informazioni su iniziative/programmi di sensibilizzazione e promozione dei diritti dei bambini</li>\n' +
    '        </ul>\n' +
    '        Per le finalità ai punti a) e b), il trattamento dei dati è ammesso senza il consenso dell’interessato ai sensi dell’art. 6 comma 1, lett. b) e c) GDPR; i dati saranno conservati per il tempo necessario a rispondere alle richieste e per eventuali contatti di approfondimento che permettano di valutare se procedere all’iscrizione all’Albo dei Volontari del Comitato Italiano per l’UNICEF - Fondazione Onlus e in ogni caso per un massimo di 12 mesi successivi alla cessazione del rapporto di volontariato.\n' +
    '        Per le finalità al punto c) il trattamento è ammesso ai sensi dell’art. 6 comma 1 lettera f) GDPR (“legittimo interesse”); i dati verranno conservati per il periodo consentito dalla legge per esigenze di tutela legale; la conservazione può essere di tempo inferiore se non si ha più interesse ad essere informati e lo si comunica con le modalità sotto riportate.\n' +
    '        Per i predetti fini saranno conservati anche successivamente, laddove leggi, regolamenti o normativa comunitaria ne determinino la conservazione per tempi differenti. Inoltre, saranno conservati in caso di imposizione da parte delle Forze dell’Ordine, della Magistratura e Organi di Controllo e per far valere o difendere diritti in sede giudiziaria. Le persone autorizzate al trattamento sono gli addetti dell’Area Volontari, il Presidente del Comitato locale di riferimento, gli addetti dei sistemi informativi e di sicurezza dei dati.\n' +
    '          Ai sensi degli artt. 15-22, GDPR, scrivendo all’e-mail infoprivacy@unicef.it, si può richiedere l’elenco completo e aggiornato dei responsabili del trattamento, nonché esercitare i diritti di consultazione, modificazione, di cancellazione e oblio, limitazione del trattamento dei dati, portabilità dei dati o opporsi al loro trattamento. Inoltre, l’interessato ha diritto di presentare reclamo all’autorità di controllo – per l’Italia è il Garante per la protezione dei dati personali – www.garanteprivacy.it, per far valere i propri diritti. Il Data Protection Officer è contattabile all’e-mail <a href="mailto:dpo@unicef.it">mailto:dpo@unicef.it</a>, per informazioni sul trattamento dei dati.\n' +
    '        Informativa completa al sito <a href="https://www.unicef.it/note-sulla-privacy">www.unicef.it/privacy</a>'
}
export default FormDiventaVolontario;
