import React from 'react';

const TextArea = ({
  label,
  required,
  className,
  inputRef,
  name
}) => {
  return (
    <div className={`textarea ${className}`}>
      <div className="label-small textarea__label">
        {label}
        {
          required && <span>*</span>
        }
      </div>
      <textarea name={name} ref={inputRef} className="textarea__input" required={required} />
    </div>
  );
};

export default TextArea;
