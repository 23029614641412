import React from 'react';
import Img from 'gatsby-image';
import Container from '../Container';

const ImageFull = ({
  caption, image, marginTop, marginBottom
}) => {
  return (
    <Container
      className="image-full"
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      <figure className="image-full__container">
        {image && (
          <Img
            fluid={image.fluid}
            alt={image.alt}
            className="image-full__photo"
          />
        )}
        <figcaption className="image-full__caption-container">
          <span className="image-full__caption caption">{caption}</span>
        </figcaption>
      </figure>
    </Container>
  );
};

ImageFull.defaultProps = {
  image: {
    fluid: {
      aspectRatio: 1.7777777777777777,
      base64: 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/2wEEEAAJAAsACwANABEADQASABQAFAASABgAGgAYABoAGAAkACEAHgAeACEAJAA2ACcAKQAnACkAJwA2AFEAMwA7ADMAMwA7ADMAUQBHAFYARwBCAEcAVgBHAIAAZQBZAFkAZQCAAJQAfAB2AHwAlACzAKAAoACzAOEA1QDhASUBJQGKEQAJAAsACwANABEADQASABQAFAASABgAGgAYABoAGAAkACEAHgAeACEAJAA2ACcAKQAnACkAJwA2AFEAMwA7ADMAMwA7ADMAUQBHAFYARwBCAEcAVgBHAIAAZQBZAFkAZQCAAJQAfAB2AHwAlACzAKAAoACzAOEA1QDhASUBJQGK/8IAEQgAEAAcAwEiAAIRAQMRAf/EABcAAAMBAAAAAAAAAAAAAAAAAAIFBgT/2gAIAQEAAAAAM0iit3nM/wD/xAAVAQEBAAAAAAAAAAAAAAAAAAABA//aAAgBAhAAAACR/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQT/2gAIAQMQAAAArP/EACcQAAEDAgUDBQEAAAAAAAAAAAECAxEABAUSITFRExRBIjJCYbHh/9oACAEBAAE/AMZw1hzELb0lbeYFQSSTB1McVdWdohgNMoQQYWNIJJ2/tN4YgGemuElQVJB186D9q8S9avFDSlBJAVAG0jz903au9q4EOEFMgRPsG0DzVpa3dwlxXcbEJEpGmm44q1U5HTU4JAVlWNt/nHNXGBMuPOKzuKJVqrNGY81//8QAGREBAAMBAQAAAAAAAAAAAAAAAgABIUGB/9oACAECAQE/AEVvNlBWvJ//xAAdEQEAAQQDAQAAAAAAAAAAAAABAgADESESFEFh/9oACAEDAQE/ALcrGlFQ+VPqFvjjIS2J7X//2Q==',
      sizes: '(max-width: 800px) 100vw, 800px',
      src: 'https://www.datocms-assets.com/30196/1595410080-bannersmall.jpg?auto=compress%2Cformat&dpr=2&fm=jpg'
    }
  }
};

export default ImageFull;
