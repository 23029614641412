import React from 'react';
import AccordionWithIntro from '../components/AccordionWithIntro';
import BannerText from '../components/BannerText';
import Numbers from '../components/Numbers';
import Download from '../components/Download';
import BannerDouble from '../components/BannerDouble';
import BannerBigImage from '../components/BannerBigImage';
import BannerFullImage from '../components/BannerFullImage';
import Quote from '../components/Quote';
import ImageFull from '../components/ImageFull';
import ImageMedium from '../components/ImageMedium';
import Mappa from '../components/Mappa';
import Paragraph from '../components/Paragraph';
import SliderPartner from '../components/SliderPartners';
import SliderStory from '../components/SliderStory';
import SliderTestimonial from '../components/SliderTestimonial';
import SliderImageGallery from '../components/SliderImageGallery';
import SliderCta from '../components/SliderCta';
import SliderImageGalleryHome from '../components/SliderImageGalleryHome';
import BannerWithIcons from '../components/BannerWithIcons';
import PeopleDetails from '../components/PeopleDetails';
import PeopleList from '../components/PeopleList';
import VideoEmbed from '../components/VideoEmbed';
import ListPartners from '../components/ListPartners';
import FormContainer from '../containers/FormContainer';
import Spacing from '../components/Spacing';
import DataVisualization from '../components/DataVisualization';

import {
  ACCORDION_WITH_INTRO,
  DOWNLOAD,
  BANNER,
  BANNERTEXTWITHMODAL,
  CENTEREDNUMBERS,
  NUMBER,
  BANNERDOUBLE,
  BANNERBIGIMAGE,
  BANNERFULLIMAGE,
  BANNERICON,
  QUOTE,
  FORM,
  IMAGEFULL,
  IMAGEMEDIUM,
  LOGOLIST,
  MAPPA,
  PARAGRAPH,
  PEOPLEDETAIL,
  PEOPLELIST,
  SPACING,
  SLIDEPARTNER,
  SLIDERSTORY,
  SLIDERTESTIMONIAL,
  SLIDERIMAGESGALLERY,
  SLIDERIMAGE,
  SLIDERSHOP,
  SLIDERGENERAL,
  VIDEOEMBED,
  DATAVISUALIZATION
} from '../visualComposerModelKeys';
import { useSizeColsIsRoot } from './archive-hooks';

export const useGetComponentByType = (root, component) => {
  const { modelName } = { modelName: component.model.apiKey };
  const {
    caption,
    cards,
    coverImage,
    colorTheme,
    content,
    contentAccordion,
    description,
    id,
    idInfogram,
    image,
    images,
    imageMobile,
    imagePosition,
    intro,
    label,
    labelButton,
    labelLink,
    list,
    listAccordion,
    listDocument,
    listSlide,
    marginTop,
    marginBottom,
    modalBanner,
    people,
    size,
    slide,
    subtitle,
    testimonials,
    title,
    url,
    formType,
    video,
  } = component;
  const sizeCols = useSizeColsIsRoot(root);
  const componentsList = {
    [ACCORDION_WITH_INTRO]: () => {
      return (
        <AccordionWithIntro
          key={id}
          intro={intro}
          title={title}
          label={label}
          description={description}
          accordions={listAccordion}
          marginTop={marginTop}
          marginBottom={marginTop}
        />
      );
    },
    [BANNER]: () => {
      return (
        <BannerText
          colorTheme={colorTheme}
          title={title}
          content={content}
          url={url}
          marginTop={marginTop}
          marginBottom={marginBottom}
          labelButton={labelButton}
        />
      );
    },
    [BANNERTEXTWITHMODAL]: () => {
      return (
        <BannerText
          colorTheme={colorTheme}
          title={title}
          content={content}
          marginTop={marginTop}
          marginBottom={marginBottom}
          labelButton={labelButton}
          paragraph={modalBanner.paragraph}
          hasModal
        />
      );
    },
    [DOWNLOAD]: () => {
      return (
        <Download
          label={label}
          listDocument={listDocument}
          marginTop={marginTop}
          marginBottom={marginBottom}
          numCols={sizeCols}
          labelButton="Download"
        />
      );
    },
    [CENTEREDNUMBERS]: () => {
      const {
        titleNumberOne, unitLengthNumberOne, descriptionNumberOne,
        titleNumberSecond, unitLengthNumberSecond, descriptionNumberSecond,
        titleNumberThird, unitLengthNumberThird, descriptionNumberThird,
      } = component;
      const numbers = [
        {
          title: titleNumberOne,
          percentage: unitLengthNumberOne,
          description: descriptionNumberOne
        },
        {
          title: titleNumberSecond,
          percentage: unitLengthNumberSecond,
          description: descriptionNumberSecond
        },
        {
          title: titleNumberThird,
          percentage: unitLengthNumberThird,
          description: descriptionNumberThird
        }
      ];
      return (
        <Numbers
          label={label}
          numbers={numbers}
          marginTop={marginTop}
          marginBottom={marginBottom}
        />
      );
    },
    [NUMBER]: () => {
      const {
        titleNumberOne, unitLengthNumberOne, descriptionNumberOne,
        titleNumberSecond, unitLengthNumberSecond, descriptionNumberSecond,
        titleNumberThird, unitLengthNumberThird, descriptionNumberThird,
      } = component;
      const numbers = [
        {
          title: titleNumberOne,
          percentage: unitLengthNumberOne,
          description: descriptionNumberOne
        },
        {
          title: titleNumberSecond,
          percentage: unitLengthNumberSecond,
          description: descriptionNumberSecond
        }
      ];
      if (titleNumberThird !== '') {
        numbers.push(
          {
            title: titleNumberThird,
            percentage: unitLengthNumberThird,
            description: descriptionNumberThird
          }
        );
      }
      return (
        <Numbers
          label={label}
          title={title}
          numbers={numbers}
          marginTop={marginTop}
          marginBottom={marginBottom}
          colorTheme={colorTheme}
        />
      );
    },
    [BANNERDOUBLE]: () => {
      const {
        titleFirst, descriptionFirst, labelFirstLink, linkFirst, imageFirst,
        titleSecond, descriptionSecond, labelSecondLink, linkSecond, imageSecond
      } = component;
      const cards = [];
      if (titleFirst) {
        cards.push({
          title: titleFirst,
          description: descriptionFirst,
          url: linkFirst,
          label: labelFirstLink,
          img: imageFirst
        });
      }
      if (titleSecond) {
        cards.push({
          title: titleSecond,
          description: descriptionSecond,
          url: linkSecond,
          label: labelSecondLink,
          img: imageSecond
        });
      }
      return (
        <BannerDouble
          marginTop={marginTop}
          marginBottom={marginBottom}
          colorTheme={colorTheme}
          cards={cards}
        />
      );
    },
    [BANNERBIGIMAGE]: () => {
      const {
        marginTop, marginBottom, label, title, description, image,
        labelButton, imagePosition, colorTheme
      } = component;
      return (
        <BannerBigImage
          marginTop={marginTop}
          marginBottom={marginBottom}
          label={label}
          title={title}
          description={description}
          image={image}
          labelButton={labelButton.value}
          imagePosition={imagePosition}
          colorTheme={colorTheme}
          url={url}
        />
      );
    },
    [BANNERFULLIMAGE]: () => {
      return (
        <BannerFullImage
          colorTheme={colorTheme}
          content={content}
          image={image}
          imageMobile={imageMobile}
          url={url}
          marginBottom={marginBottom}
          marginTop={marginTop}
          label={label}
          labelButton={labelButton.value}
        />
      );
    },
    [QUOTE]: () => {
      return (
        <Quote
          marginTop={marginTop}
          marginBottom={marginBottom}
          title={title}
          description={description}
          image={image}
          imageMobile={imageMobile}
        />
      );
    },
    [IMAGEFULL]: () => {
      return (
        <ImageFull
          caption={caption}
          image={image}
          marginTop={marginTop}
          marginBottom={marginBottom}
        />
      );
    },
    [IMAGEMEDIUM]: () => {
      return (
        <ImageMedium
          caption={caption}
          image={image}
          marginTop={marginTop}
          marginBottom={marginBottom}
        />
      );
    },
    [PARAGRAPH]: () => {
      return (
        <Paragraph
          marginTop={marginTop}
          marginBottom={marginBottom}
          content={content}
          contentAccordion={contentAccordion}
          subtitle={subtitle}
          colorTheme={colorTheme}
          numCols={useSizeColsIsRoot(root)}
        />
      );
    },
    [SLIDEPARTNER]: () => {
      const {
        partners
      } = component;
      return (
        <SliderPartner
          marginBottom={marginBottom}
          marginTop={marginTop}
          label={label}
          labelButton={labelLink}
          title={title}
          url={url}
          partners={partners}
        />
      );
    },
    [SLIDERSTORY]: () => {
      const {
        events
      } = { events: component.timeline.events };
      return (
        <SliderStory
          marginTop={marginTop}
          marginBottom={marginBottom}
          label={label}
          title={title}
          description={description}
          events={events}
        />
      );
    },
    [SLIDERTESTIMONIAL]: () => {
      return (
        <SliderTestimonial
          marginTop={marginTop}
          marginBottom={marginBottom}
          label={label}
          labelButton={labelLink}
          title={title}
          description={description}
          colorTheme={colorTheme}
          testimonials={testimonials}
        />
      );
    },
    [SLIDERIMAGESGALLERY]: () => {
      return (
        <SliderImageGallery
          marginTop={marginTop}
          marginBottom={marginBottom}
          images={images}
        />
      );
    },
    [SLIDERSHOP]: () => {
      return (
        <SliderCta
          marginTop={marginTop}
          marginBottom={marginBottom}
          label={label}
          title={title}
          description={description}
          labelButton={labelButton}
          url={url}
          cards={cards}
        />
      );
    },
    [SLIDERGENERAL]: () => {
      return (
        <SliderCta
          marginTop={marginTop}
          marginBottom={marginBottom}
          label={label}
          title={title}
          description={description}
          labelButton={labelButton}
          url={url}
          cards={slide}
        />
      );
    },
    [BANNERICON]: () => {
      return (
        <BannerWithIcons
          marginTop={marginTop}
          marginBottom={marginBottom}
          cards={cards}
        />
      );
    },
    [PEOPLEDETAIL]: () => {
      return (
        <PeopleDetails
          marginTop={marginTop}
          marginBottom={marginBottom}
          title={title}
          people={people}
          labelButton={labelLink}
          imagePosition={imagePosition}
        />
      );
    },
    [PEOPLELIST]: () => {
      return (
        <PeopleList
          marginTop={marginTop}
          marginBottom={marginBottom}
          title={title}
          content={content}
          people={people}
        />
      );
    },
    [VIDEOEMBED]: () => {
      return (
        <VideoEmbed
          marginTop={marginTop}
          marginBottom={marginBottom}
          image={coverImage}
          url={video.providerUid}
          title={video.title}
        />
      );
    },
    [SLIDERIMAGE]: () => {
      return (
        <SliderImageGalleryHome
          marginTop={marginTop}
          marginBottom={marginBottom}
          slides={listSlide}
        />
      );
    },
    [LOGOLIST]: () => {
      return (
        <ListPartners
          marginTop={marginTop}
          marginBottom={marginBottom}
          partners={list}
        />
      );
    },
    [FORM]: () => {
      return (
        <FormContainer formType={formType} />
      );
    },
    [SPACING]: () => {
      return (
        <Spacing
          size={size}
        />
      );
    },
    [MAPPA]: () => {
      return (
        <Mappa
          marginTop={marginTop}
          marginBottom={marginBottom}
          src={url}
        />
      );
    },
    [DATAVISUALIZATION]: () => {
      return (
        <DataVisualization
          marginBottom={marginBottom}
          marginTop={marginTop}
          idInfogram={idInfogram}
          title={title}
        />
      );
    }
  };
  if (componentsList[modelName]) {
    return componentsList[modelName]();
  }
};
