import React from 'react';
import Img from 'gatsby-image';
import Container from '../Container';
import { useCreateSourceHeaderImage } from '../../hooks/archive-hooks';

const Quote = ({
  marginTop, marginBottom, title, description, image, imageMobile, isEmergency
}) => {
  const classNameContainer = `quote ${image ? 'quote--with-image' : ' '} ${isEmergency ? 'quote--emergency' : ''}`;
  const classNameAuthor = `quote_paragraph no-margin ${image ? 'caption' : 'paragraph-small'}`;

  return (
    <Container
      className={classNameContainer}
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      {image && (
        <div className="quote__image-container">
          <Img
            fluid={useCreateSourceHeaderImage(image, imageMobile)}
            className="quote__image"
            alt={image.alt}
          />
        </div>
      )}
      <div className="quote__wrapper">
        <div className="quote__content">
          <h3 className="heading-md quote__title">{title}</h3>
          <div className={classNameAuthor} dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      </div>
    </Container>
  );
};
Quote.defaultProps = {
  title: "L’accesso all'istruzione rimane in cima alle nostre priorità, soprattutto per quanto riguarda gli adolescenti nei campi profughi.",
  description: '<strong>Christophe Boulierac,</strong> portavoce UNICEF a Ginevra',
};

export default Quote;
