/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import Accordion from '../components/Accordion';

// TODO Check activeAccordion on id and not on index

const withAccordionGroup = (WrappedComponent) => ({ accordions }) => {
  const [activeAccordion, setActiveAccordion] = useState(-1);
  return (
    <>
      {
        accordions && accordions.map((accordion, index) => {
          const { title, id } = accordion;
          return (
            <Accordion
              key={id}
              title={title}
              isActive={activeAccordion === index}
              handleClick={() => setActiveAccordion(index)}
            >
              <WrappedComponent {...accordion} />
            </Accordion>
          );
        })
      }
    </>
  );
};

export default withAccordionGroup;
