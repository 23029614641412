import React, { useLayoutEffect, useRef, useState } from 'react';
import Swiper, { Autoplay } from 'swiper';
import Img from 'gatsby-image';
import ProgressBar from '../ProgressBar';
import Icon from '../Icons';
import { useCheckResize } from '../../hooks/match-media';
import { ButtonLink } from '../Link';
import Linked from '../Linked';
import Logo from '../Logo';
import Container from '../Container';

const swiperOptions = {
  slidesPerView: 'auto',
  grabCursor: true,
  preloadImages: false,
  lazy: true,
  updateOnWindowResize: true,
  spaceBetween: 20,
  loop: true,
  slidesOffsetBefore: 20,
  slidesOffsetAfter: 20,
  autoplay: {
    delay: 4000,
    disableOnInteraction: false
  },
  breakpoints: {
    768: {
      spaceBetween: 88,
      slidesOffsetBefore: typeof window !== 'undefined' ? ((window.innerWidth - 720) / 2) : 0,
      slidesOffsetAfter: typeof window !== 'undefined' ? ((window.innerWidth - 720) / 2) : 0,
      grabCursor: false
    },
    1024: {
      spaceBetween: 104,
      slidesOffsetBefore: 20,
      slidesOffsetAfter: 20,
    },
    1366: {
      spaceBetween: 104,
      slidesOffsetBefore: 32,
      slidesOffsetAfter: 32,
    }
  },
};

const SliderCta = ({
  marginTop, marginBottom, label, title, description, cards, labelButton, url
}) => {
  const [mySwiper, setMySwiper] = useState(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(1);

  const swiperRef = useRef(null);
  useLayoutEffect(() => {
    Swiper.use(Autoplay);
    const swiper = new Swiper(swiperRef.current, {
      ...swiperOptions,
      on: {
        slideChange: (swiper) => {
          // setCurrentSlideIndex(swiper.activeIndex + 1);
          const { activeIndex, slides } = swiper;
          let realIndex = 0;
          if (slides[activeIndex]) {
            realIndex = +slides[activeIndex].getAttribute('data-swiper-slide-index') + 1;
          }
          setCurrentSlideIndex(realIndex);
        },
        resize: () => {
          if (swiper.currentBreakpoint === '768') {
            swiper.params.slidesOffsetBefore = window !== 'undefined' ? ((window.innerWidth - 720) / 2) : 0;
            swiper.params.slidesOffsetAfter = window !== 'undefined' ? ((window.innerWidth - 720) / 2) : 0;
            swiper.snapGrid = [...swiper.slidesGrid];
            swiper.slideToClosest();
          } else if (swiper.currentBreakpoint === '1024') {
            swiper.params.slidesOffsetBefore = 20;
            swiper.params.slidesOffsetAfter = 20;
            swiper.snapGrid = [...swiper.slidesGrid];
            swiper.slideToClosest();
          } else if (swiper.currentBreakpoint === '1366') {
            swiper.params.slidesOffsetBefore = 32;
            swiper.params.slidesOffsetAfter = 32;
            swiper.snapGrid = [...swiper.slidesGrid];
            swiper.slideToClosest();
          }
        },
      },
    });
    swiper.snapGrid = [...swiper.slidesGrid];
    setMySwiper(swiper);
  }, []);

  const sliderPagination = `${currentSlideIndex} / ${cards.length}`;

  return (
    <Container
      className="slider-cta"
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      <SliderController
        mySwiper={mySwiper}
        className="slider-cta__overlay--top"
      />
      <div className="slider-cta__container">
        <header className="slider-cta__header">
          <div className="slider-cta__header-container">
            <span className="label-small text-color-brand">{label}</span>
            <h3 className="slider-cta__title heading-sm text-color-white">{title}</h3>
            <p className="slider-cta__description paragraph-small">{description}</p>
            <div className="slider-cta__btn-container--desktop">
              <ButtonLink
                label={labelButton && labelButton.value}
                color="brand-on-black"
                kind="primary"
                className="slider-cta__btn"
                url={url}
              />
            </div>
          </div>
        </header>
        <div className="slider-cta__slider">
          <SliderController
            mySwiper={mySwiper}
            className="slider-cta__overlay--tablet-landscape"
          />
          <div ref={swiperRef} className="swiper-container">
            <div className="swiper-wrapper">
              {cards && cards.map((card) => {
                const {
                  id, title, description, image, price, url
                } = card;
                return (
                  <Slide
                    key={id}
                    title={title}
                    description={description}
                    image={image}
                    price={price}
                    url={url}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="slider-cta__bottom-wrapper">
        <div className="slider-cta__progressbar">
          <ProgressBar duration={4} activeIndex={currentSlideIndex} isActive />
          <span className="label-small text-color-white slider-cta__pagination">
            {sliderPagination}
          </span>
        </div>
      </div>
      {
        url && (
          <div className="slider-cta__btn-container--mobile">
            <ButtonLink
              label={labelButton && labelButton.value}
              color="brand"
              kind="primary"
              className="slider-cta__btn"
              url={url}
            />
          </div>
        )
      }
      <Logo className="logo--white slider-cta__logo" />
    </Container>
  );
};

const SliderController = ({ mySwiper, className }) => {
  const overlayRef = useRef(null);
  const setMarginLeft = () => {
    overlayRef.current.style.left = (window !== 'undefined')
      ? `${((window.innerWidth - 720) / 2) + (window.innerWidth * 0.70) + 24}px` : 0;
  };
  useLayoutEffect(() => {
    setMarginLeft();
  }, [overlayRef]);

  useCheckResize(setMarginLeft);

  return (
    <div className={`slider-cta__overlay ${className}`}>
      <div ref={overlayRef} className="slider-cta__overlay-inner">
        <button
          onClick={() => mySwiper.slideNext()}
          className="slider-cta__control"
          type="button"
        >
          <Icon path="arrow-big-right" className="icon--40" />
        </button>
        <button
          onClick={() => mySwiper.slidePrev()}
          className="slider-cta__control"
          type="button"
        >
          <Icon path="arrow-big-left" className="icon--40" />
        </button>
      </div>
    </div>
  );
};

const Slide = ({
  title, description, image, price, url
}) => {
  const {
    fluid, alt
  } = image;
  return (
    <div className="swiper-slide slide-cta">
      <Linked url={url}>
        <div className="slide-cta__cover">
          <Img
            fluid={{ ...fluid, aspectRatio: 16 / 9 }}
            alt={alt}
          />
        </div>
        <div className="slide-cta__bottom-card">
          <div className="slide-cta__content">
            {title && <h4 className="heading-xs slide-cta__content-title">{title}</h4>}
            {description && <p className="caption">{description}</p>}
          </div>
          {
            price && (
              <div className="heading-xs slide-cta__price">
                {`${price} €`}
              </div>
            )
          }
        </div>
      </Linked>
    </div>
  );
};

export default SliderCta;
