/* eslint-disable react/no-array-index-key */
import React from 'react';
import Img from 'gatsby-image';
import Linked from '../Linked';
import { ButtonLink } from '../Link';
import Container from '../Container';

const BannerDouble = ({
  colorTheme, marginTop, marginBottom, cards
}) => {
  const dictionary = {
    'sfondo-bianco': '1',
    'sfondo-grigio': '2',
  };

  const theme = dictionary[colorTheme];
  return (
    <Container
      className={`banner-double banner-double--theme-v${theme}`}
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      <div className="banner-double__wrapper">
        <div className="banner-double__cards-container">
          {
            cards && cards.map((card, index) => {
              const {
                img, title, description, url, label, originalId
              } = card;
              return (
                <CardBannerDouble
                  img={img}
                  title={title}
                  description={description}
                  url={url}
                  label={label}
                  key={`${originalId}-${index}`}
                />
              );
            })
          }
        </div>
      </div>
    </Container>
  );
};

const CardBannerDouble = ({
  img, title, description, url, label
}) => {
  const { alt, fluid } = img || {};
  const { value } = label || '';
  return (
    <div
      className="card-banner-double"
    >
      <Linked
        url={url}
        className="card-banner-double__link"
      >
        <figure className="card-banner-double__image-container">
          <Img
            alt={alt}
            fluid={{
              ...fluid,
              aspectRatio: 16 / 9
            }}
            className="card-banner-double__image"
          />
        </figure>
      </Linked>
      <div className="card-banner-double__text-container">
        <h3 className="card-banner-double__title heading-sm">{title}</h3>
        <p className="card-banner-double__description paragraph-small">{description}</p>
        <ButtonLink
          label={value}
          color="brand"
          kind="secondary"
          url={url}
        />
      </div>
    </div>
  );
};

BannerDouble.defaultProps = {
  cards: [
    {
      img: {
        fluid: {
          aspectRatio: 1.7777777777777777,
          base64: 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/2wEEEAAJAAsACwANABEADQASABQAFAASABgAGgAYABoAGAAkACEAHgAeACEAJAA2ACcAKQAnACkAJwA2AFEAMwA7ADMAMwA7ADMAUQBHAFYARwBCAEcAVgBHAIAAZQBZAFkAZQCAAJQAfAB2AHwAlACzAKAAoACzAOEA1QDhASUBJQGKEQAJAAsACwANABEADQASABQAFAASABgAGgAYABoAGAAkACEAHgAeACEAJAA2ACcAKQAnACkAJwA2AFEAMwA7ADMAMwA7ADMAUQBHAFYARwBCAEcAVgBHAIAAZQBZAFkAZQCAAJQAfAB2AHwAlACzAKAAoACzAOEA1QDhASUBJQGK/8IAEQgAEAAcAwEiAAIRAQMRAf/EABcAAAMBAAAAAAAAAAAAAAAAAAIFBgT/2gAIAQEAAAAAM0iit3nM/wD/xAAVAQEBAAAAAAAAAAAAAAAAAAABA//aAAgBAhAAAACR/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQT/2gAIAQMQAAAArP/EACcQAAEDAgUDBQEAAAAAAAAAAAECAxEABAUSITFRExRBIjJCYbHh/9oACAEBAAE/AMZw1hzELb0lbeYFQSSTB1McVdWdohgNMoQQYWNIJJ2/tN4YgGemuElQVJB186D9q8S9avFDSlBJAVAG0jz903au9q4EOEFMgRPsG0DzVpa3dwlxXcbEJEpGmm44q1U5HTU4JAVlWNt/nHNXGBMuPOKzuKJVqrNGY81//8QAGREBAAMBAQAAAAAAAAAAAAAAAgABIUGB/9oACAECAQE/AEVvNlBWvJ//xAAdEQEAAQQDAQAAAAAAAAAAAAABAgADESESFEFh/9oACAEDAQE/ALcrGlFQ+VPqFvjjIS2J7X//2Q==',
          sizes: '(max-width: 800px) 100vw, 800px',
          src: 'https://www.datocms-assets.com/30196/1595410080-bannersmall.jpg?auto=compress%2Cformat&dpr=2&fm=jpg'
        }
      },
      title: 'Lorem ipsum dolor sit amet',
      description: 'Scopri le iniziative speciali da sostenere e scegli di dare un aiuto concreto a che ne ha bisogno.',
      url: 'http://www.google.it'
    },
    {
      img: {
        fluid: {
          aspectRatio: 1.7777777777777777,
          base64: 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/2wEEEAAJAAsACwANABEADQASABQAFAASABgAGgAYABoAGAAkACEAHgAeACEAJAA2ACcAKQAnACkAJwA2AFEAMwA7ADMAMwA7ADMAUQBHAFYARwBCAEcAVgBHAIAAZQBZAFkAZQCAAJQAfAB2AHwAlACzAKAAoACzAOEA1QDhASUBJQGKEQAJAAsACwANABEADQASABQAFAASABgAGgAYABoAGAAkACEAHgAeACEAJAA2ACcAKQAnACkAJwA2AFEAMwA7ADMAMwA7ADMAUQBHAFYARwBCAEcAVgBHAIAAZQBZAFkAZQCAAJQAfAB2AHwAlACzAKAAoACzAOEA1QDhASUBJQGK/8IAEQgAEAAcAwEiAAIRAQMRAf/EABcAAAMBAAAAAAAAAAAAAAAAAAIFBgT/2gAIAQEAAAAAM0iit3nM/wD/xAAVAQEBAAAAAAAAAAAAAAAAAAABA//aAAgBAhAAAACR/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQT/2gAIAQMQAAAArP/EACcQAAEDAgUDBQEAAAAAAAAAAAECAxEABAUSITFRExRBIjJCYbHh/9oACAEBAAE/AMZw1hzELb0lbeYFQSSTB1McVdWdohgNMoQQYWNIJJ2/tN4YgGemuElQVJB186D9q8S9avFDSlBJAVAG0jz903au9q4EOEFMgRPsG0DzVpa3dwlxXcbEJEpGmm44q1U5HTU4JAVlWNt/nHNXGBMuPOKzuKJVqrNGY81//8QAGREBAAMBAQAAAAAAAAAAAAAAAgABIUGB/9oACAECAQE/AEVvNlBWvJ//xAAdEQEAAQQDAQAAAAAAAAAAAAABAgADESESFEFh/9oACAEDAQE/ALcrGlFQ+VPqFvjjIS2J7X//2Q==',
          sizes: '(max-width: 800px) 100vw, 800px',
          src: 'https://www.datocms-assets.com/30196/1595410080-bannersmall.jpg?auto=compress%2Cformat&dpr=2&fm=jpg'
        }
      },
      title: 'Lorem ipsum dolor sit amet',
      description: 'Scopri le iniziative speciali da sostenere e scegli di dare un aiuto concreto a che ne ha bisogno.',
      url: 'http://www.unicef.it/chi-siamo'
    }
  ]
};

export default BannerDouble;
