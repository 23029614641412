/* eslint-disable */
import React, { useLayoutEffect, useRef, useState } from 'react';
import Swiper, { Pagination } from 'swiper';
import Icon from '../Icons';
import { ButtonLink } from '../Link';
import Container from '../Container';
import PeopleModal from "../PeopleModal"

const SliderPartner = ({
  marginTop, marginBottom, label, title, url, labelButton, partners
}) => {
  const [mySwiper, setMySwiper] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperContainer = useRef(null);

  const [modal, setModal] = useState(-1);

  const closeModal = () => {
    setModal(-1);
  };

  const swiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 20,
    grabCursor: true,
    preloadImages: false,
    lazy: true,
    pagination: {
      el: '.partners__progress-runner',
      clickable: true,
      type: 'progressbar',
    },
    breakpoints: {
      320: {
        spaceBetween: 20,
        slidesPerView: 'auto',
        slidesOffsetAfter: 20
      },
      768: {
        spaceBetween: 24,
        slidesPerView: 5,
        slidesOffsetAfter: 0
      },
      1440:  {
        spaceBetween: 24
      }
    },
    on: {
      slideChange: (swiper) => {
        setActiveIndex(swiper.activeIndex);
      }
    }
  };


  useLayoutEffect(() => {
    Swiper.use(Pagination);
    const swiper = new Swiper(swiperContainer.current, swiperOptions);
    setMySwiper(swiper);
  }, []);

  const slideNext = () => {
    if (mySwiper) {
      mySwiper.slideNext();
    }
  };

  const slidePrev = () => {
    if (mySwiper) {
      mySwiper.slidePrev();
    }
  };

  return (
    <Container
      className="partners"
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      <div className="partners__wrapper">
        <div className="partners__header">
          {
            label && (
              <span className="label-small text-color-brand">{label}</span>
            )
          }
          <div className="partners__title-container">
            {title && <h3 className="heading-md partners__title">{title}</h3>}
            {
              url && (
                <ButtonLink
                  className="partners__show-more partners__show-more--top"
                  kind="secondary"
                  label={labelButton}
                  color="brand"
                  url={url}
                />
              )
            }
          </div>
        </div>

        <div className="partners__slider">
          <div ref={swiperContainer} className="swiper-container">
            <div className="swiper-wrapper">
              {partners && partners.map((partner, index) => {
                const {
                  id, image, title, url
                } = partner;
                return (
                  <div className="swiper-slide partners__slide" key={id}>
                    <button type="button" onClick={() => setModal(index)}>
                      <figure>
                        <div className="partners__slide-card">
                          <img src={image.url} alt={image.alt} />
                        </div>
                        {title && <figcaption className="label-small partners__slide-caption">{title}</figcaption>}
                      </figure>
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="partners__bottom">
          <div className="partners__progress-bar">
            <div className="partners__progress-runner" />
          </div>
          <div className="partners__controllers">
            <button onClick={() => slidePrev()} className="partners__button-prev" type="button">
              <Icon path="arrow-big-left" className="icon--40" />
            </button>
            <button onClick={() => slideNext()} className="partners__button-next" type="button">
              <Icon path="arrow-big-right" className="icon--40" />
            </button>
          </div>
        </div>
        {
          url &&  (
            <ButtonLink
              className="partners__show-more partners__show-more--bottom"
              kind="secondary"
              label="vedi tutti"
              color="brand"
            />
          )
        }
      </div>
      <PeopleModal
        people={partners}
        hasLogos
        closeModal={closeModal}
        modal={modal}
      />
    </Container>
  );
};

export default SliderPartner;
