import React, {
  useState, useRef, useEffect
} from 'react';
import Img from 'gatsby-image';
import Container from '../Container';
import Button from '../Button';
import PeopleModal from '../PeopleModal';

const PeopleDetails = ({
  marginTop, marginBottom, title, people, labelButton
}) => {
  const [modal, setModal] = useState(-1);

  const closeModal = () => {
    setModal(-1);
    document.querySelector('body').classList.remove('no-scroll');
  };

  useEffect(() => {
    if (modal >= 0) {
      document.querySelector('body').classList.add('no-scroll');
    } else {
      document.querySelector('body').classList.remove('no-scroll');
    }
  }, [modal]);
  return (
    <Container
      className="people-details"
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      <div className="people-details__wrapper">
        {title && <h2 className="people-details__title heading-md">{title}</h2>}
        {
          people && people.map((person, index) => {
            const {
              id, image, role, intro, biography, imagePosition, isCircleImage
            } = person;
            return (
              <Person
                key={id}
                image={image}
                biography={biography}
                labelButton={labelButton}
                role={role}
                intro={intro}
                index={index}
                setModal={setModal}
                imagePosition={imagePosition}
                isCircleImage={isCircleImage}
              />
            );
          })
        }
        <PeopleModal people={people} closeModal={closeModal} modal={modal} />
      </div>
    </Container>
  );
};

const Person = ({
  image,
  intro,
  biography,
  role,
  index,
  labelButton,
  setModal,
  imagePosition,
  isCircleImage
}) => {
  const [position, setPosition] = useState('sx');
  const paragraphRef = useRef(null);

  useEffect(() => {
    if (imagePosition === 'sinistra') {
      setPosition('sx');
    } else if (imagePosition === 'destra') {
      setPosition('dx');
    }
  }, [imagePosition]);

  return (
    <div className={`people-details__container people-details__container--position-${position}`}>
      {
        position === 'sx' && image && (
          <Img
            fluid={image.fluid}
            className={`people-details__image ${isCircleImage ? 'is-circle' : ''}`}
            alt={image.alt}
          />
        )
      }
      <div className="people-details__text-container">
        {role && <h3 className="people-details__label label">{role}</h3>}
        {
          intro && (
            <div
              ref={paragraphRef}
              className="people-details__paragraph paragraph"
              dangerouslySetInnerHTML={{ __html: `${intro}` }}
            />
          )
        }
        {
          biography && (
            <Button
              handleClick={() => setModal(index)}
              kind="secondary"
              label={labelButton || 'Continua a leggere'}
              color="brand"
            />
          )
        }
      </div>
      {
        position === 'dx' && image && (
          <Img
            fluid={image.fluid}
            className={`people-details__image ${isCircleImage ? 'is-circle' : ''}`}
            alt={image.alt}
          />
        )
      }
    </div>
  );
};

export default PeopleDetails;
