/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import { useGetComponentByType } from '../../hooks/visual-composer';

const VisualComposer = ({
  root, components, news, labels
}) => {
  return (
    <>
      {components
        && components.map((component, index) => {
          /** Using an external HOOK for sorting different components to render */
          if (component.model) {
            return (
              <Fragment key={component.id + index}>
                {useGetComponentByType(root, component, news, labels)}
              </Fragment>
            );
          }
          return <Fragment key={Math.random() * 100} />;
        })}
    </>
  );
};

export default VisualComposer;
