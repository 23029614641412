import React from 'react';
import TextField from '@material-ui/core/TextField';

const TextFieldCustom = ({
  label,
  type,
  required,
  id,
  placeholder,
  className,
  handleChange,
  inputRef,
  name,
  error
}) => {
  const handleFieldChange = (e) => {
    if (handleChange) {
      handleChange(e);
    }
  };
  const textfieldError = `styled-text-field ${error ? 'styled-input--with-error' : ''}`;
  return (
    <div className={`styled-text-field__wrapper ${className || ''}`}>
      <TextField
        inputRef={inputRef}
        placeholder={placeholder}
        required={required}
        // className="styled-text-field"
        className={textfieldError}
        name={name}
        id={id}
        label={label}
        type={type}
        onChange={(e) => handleFieldChange(e)}
      />
      {
        error
        && (
          <span className="caption text-color-orange  styled-error">
            {error}
          </span>
        )
      }
    </div>
  );
};

TextField.defaultProps = {
  label: '',
  type: 'text',
  required: false,
  id: 'demo-input-1',
  placeholder: '',
  ref: null
};

export default TextFieldCustom;
