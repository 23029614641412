/* eslint-disable react/no-array-index-key */
import React from 'react';
import Container from '../Container';

const Numbers = ({
  label, title, numbers, colorTheme, marginTop, marginBottom
}) => {
  const classNameSection = title ? 'numbers numbers--with-title' : 'numbers';
  const nItemsClasses = `numbers__wrapper ${numbers.length === 2 ? 'numbers__wrapper numbers--2-items' : ' '}`;
  let themeClasses = '';
  if (colorTheme) {
    themeClasses = (colorTheme === 'sfondo-nero') ? 'numbers--theme-v2' : 'numbers--theme-v3';
  }

  return (
    <Container
      className={`${classNameSection} ${themeClasses}`}
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      <div className={nItemsClasses}>
        <header className={`numbers__header ${!label ? 'no-border-top' : ''}`}>
          {
            label && <div className="numbers__label label">{label}</div>
          }
          {
            title && (
              <h3 className="numbers__title heading-md">{title}</h3>
            )
          }
        </header>
        <div className="numbers__list">
          {
            numbers && numbers.map((number, index) => {
              const { title, percentage, description } = number;
              return (
                <Number
                  key={index}
                  title={title}
                  percentage={percentage}
                  description={description}
                />
              );
            })
          }
        </div>
      </div>
    </Container>
  );
};

const Number = ({ title, percentage, description }) => {
  return (
    <div className="number">
      <p className="number__title heading-xl">
        {title}
        <span className="number__percentage heading-xs--brand">{percentage}</span>
      </p>
      <p className="number__description label">
        {description}
      </p>
    </div>
  );
};

export default Numbers;
