/* eslint-disable */
import React, { useState } from "react"
import { useForm, Controller } from 'react-hook-form';
import TextField from '../TextFieldCustom';
import Select from '../Select';
import Button from '../Button';
import { useSubmitForm } from '../../hooks/hook.submit-form';
import provinceItaliane from '../../assets/province-italiane.json';
import FormScuolaProposteEducative from "./form.scuola-proposte-educative"

const FormUnicefMiur = ({ setShowThankYouPage, disclaimer }) => {
  const {
    handleSubmit,
    register,
    errors,
    control,
  } = useForm({
    mode: 'onFocus'
  });

  const [loadingOnSubmit, setLoadingOnSubmit] = useState({
    label: 'Invia',
    disabled: false
  });

  const onSubmit = (data) => {
    setLoadingOnSubmit({
        label: 'Sto Inviando',
        disabled: true
      }
    );
    useSubmitForm(
      data,
      430,
      () => setShowThankYouPage(true),
      () => {
        setLoadingOnSubmit({
            label: 'Invia',
            disabled: false
          }
        );
        alert('Ops, errore di rete, ritenta l\'invio');
      }
    );
  };

  return (
    <form noValidate className="form-group" onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group__container">
        <h3 className="form-group__title heading-md">Unicef MIUR</h3>

        {/** NAME */}
        <TextField
          className="form-group__textfield"
          type="text"
          name="Denominazione Istituto"
          label="Denominazione Istituto"
          error={errors['Denominazione Istituto'] ? errors['Denominazione Istituto'].message : ''}
          required
          inputRef={register({
            required: 'Campo obbligatorio'
          })}
        />

        {/** ADRESS */}
        <TextField
          className="form-group__textfield"
          type="text"
          label="Indirizzo"
          name="Indirizzo"
          error={errors['Indirizzo'] ? errors['Indirizzo'].message : ''}
          required
          inputRef={register({
            required: 'Campo obbligatorio'
          })}
        />

        {/** CAP */}
        <TextField
          type="text"
          name="cap"
          label="Cap"
          className="form-group__textfield"
          error={errors['cap'] ? errors['cap'].message : ''}
          required
          inputRef={register({
            required: 'Campo obbligatorio'
          })}
        />

        {/**  */}
        <TextField
          type="text"
          name="Comune"
          label="Comune"
          className="form-group__textfield"
          error={errors['Comune'] ? errors['Comune'].message : ''}
          required
          inputRef={register({
            required: 'Campo obbligatorio'
          })}
        />

        {/** CITY */}
        <TextField
          type="text"
          label="Città"
          name="Citta"
          className="form-group__textfield"
          error={errors['Citta'] ? errors['Citta'].message : ''}
          inputRef={register({
            required: 'Campo obbligatorio'
          })}
        />

        {/** PROVINCE */}
        <Controller
          control={control}
          name="Provincia"
          rules={{ required: 'Campo obbligatorio' }}
          render={({ onChange }) => (
            <Select
              className="form-group__select"
              label="Provincia"
              name="Provincia"
              options={provinceItaliane}
              handleChange={(value) => onChange(value)}
              error={errors['Provincia'] ? errors['Provincia'].message : ''}
              required
            />
          )}
        />

        {/** TELEPHONE */}
        <TextField
          type="text"
          name="Telefono"
          label="Telefono"
          className="form-group__textfield"
          error={errors['Telefono'] ? errors['Telefono'].message : ''}
          required
          inputRef={register({
            required: 'Campo obbligatorio',
            minLength: {
              value: 5,
              message: 'Il numero deve essere almeno di 5 cifre',
            },
            pattern: {
              value: /[+0-9]+/,
              message: 'Il formato non corrisponde'
            }
          })}
        />

        {/** EMAIL */}
        <TextField
          type="text"
          name="Email Scuola"
          label="Email Scuola"
          className="form-group__textfield"
          error={errors['Email Scuola'] ? errors['Email Scuola'].message : ''}
          required
          inputRef={register({
            required: 'Campo obbligatorio',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Formato email non valido'
            }
          })}
        />

        {/** TEACHER'S NAME */}
        <TextField
          type="text"
          name="Nome Insegnante"
          label="Nome Insegnante"
          className="form-group__textfield"
          error={errors['Nome Insegnante'] ? errors['Nome Insegnante'].message : ''}
          required
          inputRef={register({
            required: 'Campo obbligatorio'
          })}
        />

        {/** TEACHER'S SURNAME */}
        <TextField
          type="text"
          name="Cognome Insegnante"
          label="Cognome Insegnante"
          className="form-group__textfield"
          error={errors['Cognome Insegnante'] ? errors['Cognome Insegnante'].message : ''}
          required
          inputRef={register({
            required: 'Campo obbligatorio'
          })}
        />

        {/** TEACHER'S TELEPHONE */}
        <TextField
          type="text"
          name="Telefono Insegnante"
          label="Telefono Insegnante"
          className="form-group__textfield"
          error={errors['Telefono Insegnante'] ? errors['Telefono Insegnante'].message : ''}
          required
          inputRef={register({
            required: 'Campo obbligatorio',
            minLength: {
              value: 5,
              message: 'Il numero deve essere almeno di 5 cifre',
            },
            pattern: {
              value: /[+0-9]+/,
              message: 'Il formato non corrisponde'
            }
          })}
        />

        {/** TEACHER'S EMAIL */}
        <TextField
          type="text"
          name="Email Insegnante"
          label="Email Insegnante"
          className="form-group__textfield"
          error={errors['Email Insegnante'] ? errors['Email Insegnante'].message : ''}
          required
          inputRef={register({
            required: 'Campo obbligatorio',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Formato email non valido'
            }
          })}
        />

        {/** PRIVACY POLICY */}
        <div className="paragraph-small" dangerouslySetInnerHTML={{__html: disclaimer}} />
        <div className="form-group__privacy">
          {/** SUBMIT BUTTON */}
          <Button type="submit" kind="primary" disabled={loadingOnSubmit.disabled} label={loadingOnSubmit.label} color="brand" />
        </div>
      </div>
    </form>
  );
};

FormUnicefMiur.defaultProps = {
  disclaimer:
    '          <strong>Informativa ex art.13, GDPR</strong>\n' +
    '\n' +
    '          <p>Titolare del trattamento dei dati è il Comitato Italiano per l’UNICEF - Fondazione ONLUS , Via Palestro 68, 00185 Roma (RM).\n' +
    '            I dati personali saranno trattati con modalità prevalentemente elettroniche per le seguenti finalità:</p>\n' +
    '          <ul>\n' +
    '            <li>a) Inviare il materiale richiesto e/o dare riscontro alle richieste avanzate per l’adesione al Percorso di interesse</li>\n' +
    '            <li>b) Ottemperare a norme di legge o regolamento</li>\n' +
    '            <li>c) Fornire informazioni su iniziative/programmi di sensibilizzazione e promozione dei diritti dei bambini</li>\n' +
    '          </ul>\n' +
    '          <p>Per le finalità ai punti a) e b), il trattamento dei dati è ammesso senza il consenso dell’interessato ai sensi dell’art. 6 comma 1, lett. b) e c)\n' +
    '            GDPR; i dati saranno conservati per il tempo necessario a rispondere alle richieste e per eventuali contatti di approfondimento che permettano di\n' +
    '            valutare se aderire all’ iniziativa di interesse; in ogni caso per il periodo consentito dalla legge per esigenze di tutela legale. Per le finalità al\n' +
    '            punto c) il trattamento è ammesso ai sensi dell’art. 6 comma 1 lettera f) GDPR (“legittimo interesse”); i dati verranno conservati per il periodo\n' +
    '            consentito dalla legge per esigenze di tutela legale; la conservazione può essere di tempo inferiore se non si ha più\n' +
    '            interesse ad essere informati e lo si comunica con le modalità sotto riportate.</p>\n' +
    '          <p>Per i predetti fini saranno conservati anche successivamente, laddove leggi, regolamenti o normativa comunitaria ne determinino\n' +
    '            la conservazione per tempi differenti. Inoltre, saranno conservati in caso di imposizione da parte delle Forze dell’Ordine,\n' +
    '            della Magistratura e Organi di Controllo e per far valere o difendere diritti in sede giudiziaria. Le persone autorizzate al trattamento sono\n' +
    '            gli addetti all’amministrazione e organizzatori dell’iniziativa, alle attività istituzionali e statutarie, dei sistemi informativi e di sicurezza dei dati.</p>\n' +
    '          <p>Ai sensi degli artt. 15-22, GDPR, scrivendo all’e-mail <a href="mailto:infoprivacy@unicef.it">infoprivacy@unicef.it</a>,\n' +
    '            si può richiedere l’elenco completo e aggiornato dei responsabili del trattamento, nonché esercitare i diritti di consultazione,\n' +
    '            modificazione, di cancellazione e oblio, limitazione del trattamento dei dati, portabilità dei dati o opporsi al loro trattamento. Inoltre,\n' +
    '            l’interessato ha diritto di presentare reclamo all’autorità di controllo – per l’Italia è il Garante per la protezione dei dati personali\n' +
    '            – <a href="https://www.garanteprivacy.it">www.garanteprivacy.it</a>, per far valere i propri diritti. Il Data Protection Officer\n' +
    '            è contattabile all’e-mail <a href="mailto:dpo@unicef.it">dpo@unicef.it</a>, per informazioni sul trattamento dei dati.</p>\n' +
    '\n' +
    '          Informativa completa al sito <a href="https://www.unicef.it/note-sulla-privacy">www.unicef.it/privacy</a>\n'
}

export default FormUnicefMiur;
