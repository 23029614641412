import React, {
  useState, useLayoutEffect, useRef, useEffect
} from 'react';
import { MDCSelect } from '@material/select';
import SelectOption from '../SelectOption';

const Select = ({
  options, label, value, className, handleChange, required, error
}) => {
  const [selectedOption, setSelectedOption] = useState({});
  const selectRef = useRef(null);
  useEffect(() => {
    const defaultSelectedIndex = options.map((option) => option.value).indexOf(value);
    const defaultSelected = options[defaultSelectedIndex];
    setSelectedOption(defaultSelected);
  }, [value, options]);

  useLayoutEffect(() => {
    new MDCSelect(selectRef.current);
  }, []);

  const handleSelectChange = (label, value) => {
    setSelectedOption({ label, value });
    handleChange(value, label); // Callback;
  };

  const classes = `styled-select mdc-select mdc-select--filled demo-width-class ${className || ''} ${error ? 'styled-input--with-error' : ''}`;

  return (
    <div ref={selectRef} className={classes}>
      <div className="mdc-select__anchor">
        <span className="mdc-select__ripple" />
        <span className="mdc-select__selected-text">{selectedOption ? selectedOption.label : ''}</span>
        <span className="mdc-select__dropdown-icon">
          <svg
            className="mdc-select__dropdown-icon-graphic"
            viewBox="7 10 10 5"
          />
        </span>
        <span className={`mdc-floating-label label-small ${(selectedOption && selectedOption.label !== '') ? 'mdc-floating-label--floating' : ''}`}>
          {label}
          {required ? <span className="mdc-floating-label--required text-color-orange"> *</span> : ''}
        </span>
        <span className="mdc-line-ripple" />
      </div>
      <div className="mdc-select__menu mdc-menu mdc-menu-surface mdc-menu-surface--fullwidth">
        <ul className="mdc-list">
          {
            options.map((option) => {
              const { label, value } = option;
              return (
                <SelectOption
                  key={value}
                  label={label}
                  value={value}
                  isSelected={false}
                  onClick={(label, value) => handleSelectChange(label, value)}
                />
              );
            })
          }
        </ul>
      </div>
      {
        error
        && (
          <span className="caption text-color-orange styled-error">
            {error}
          </span>
        )
      }
    </div>
  );
};

export default Select;

Select.defaultProps = {
  options: [
    {
      label: 'Fruit',
      value: 'fruit',
    },
    {
      label: 'Vegetables',
      value: 'vegetables'
    }
  ],
  label: 'Pick a Food Group',
  handleChange: () => { }
};
