/* eslint-disable default-case */
import React from 'react';
import FormBabyPitStop from '../../components/FormsTemplate/form.baby-pit-stop';
import FormCittaAmica from '../../components/FormsTemplate/form.citta-amica';
import FormComunitaAmica from '../../components/FormsTemplate/form.comunita-amica';
import FormCorsiLaureaAmici from '../../components/FormsTemplate/form.corsi-laurea-amici';
import FormCumesRoma from '../../components/FormsTemplate/form.cumes-roma';
import FormCumedMilano2022 from '../../components/FormsTemplate/form.cumed-milano-2022';
import FormCumedSassari2022 from '../../components/FormsTemplate/form.cumed-sassari-2022';
import FormCumedSiena2022 from '../../components/FormsTemplate/form.cumed-siena-2022';
import FormCumedComo2022 from '../../components/FormsTemplate/form.cumed-como-2022';
import FormDiventaVolontario from '../../components/FormsTemplate/form.diventa-volontario';
import FormGenerico from '../../components/FormsTemplate/form.generico';
import FormMediaGiornalisti from '../../components/FormsTemplate/form.media-giornalisti';
import FormMuseiBiblioteche from '../../components/FormsTemplate/form.musei-biblioteche';
import FormOspedaliAmici from '../../components/FormsTemplate/form.ospedali-amici';
import FormScuolaProposteEducative from '../../components/FormsTemplate/form.scuola-proposte-educative';
import FormUnicefMiur from '../../components/FormsTemplate/form.unicef-miur';
import FormPromoterSociale from '../../components/FormsTemplate/form.promoter-sociale';
import withForm from '../../hoc/with-form';
import FormCumedPiemonte from '../../components/FormsTemplate/form.cumed-piemonte';
import FormCumedFirenze2024 from '../../components/FormsTemplate/form.cumed-firenze-2024';
import FormCumedCagliari2024 from '../../components/FormsTemplate/form.cumed-cagliari-2024';

const FormContainer = ({ formType }) => {
  switch (formType) {
    case 'baby-pit-stop': {
      const FormContainer = withForm(FormBabyPitStop);
      return (
        <FormContainer />
      );
    }
    case 'citta-amica': {
      const FormContainer = withForm(FormCittaAmica);
      return (
        <FormContainer />
      );
    }
    case 'comunita-amica': {
      const FormContainer = withForm(FormComunitaAmica);
      return (
        <FormContainer />
      );
    }
    case 'corsi-laurea-amici': {
      const FormContainer = withForm(FormCorsiLaureaAmici);
      return (
        <FormContainer />
      );
    }
    case 'cumes-roma': {
      const FormContainer = withForm(FormCumesRoma);
      return (
        <FormContainer />
      );
    }
    case 'cumed-milano-2022': {
      const FormContainer = withForm(FormCumedMilano2022);
      return (
        <FormContainer />
      );
    }
    case 'cumed-sassari-2022': {
      const FormContainer = withForm(FormCumedSassari2022);
      return (
        <FormContainer />
      );
    }
    case 'cumed-siena-2022': {
      const FormContainer = withForm(FormCumedSiena2022);
      return (
        <FormContainer />
      );
    }
    case 'cumed-como-2022': {
      const FormContainer = withForm(FormCumedComo2022);
      return (
        <FormContainer />
      );
    }
    case 'cumed-firenze-2024': {
      const FormContainer = withForm(FormCumedFirenze2024);
      return (
        <FormContainer />
      );
    }
    case 'cumed-cagliari-2024': {
      const FormContainer = withForm(FormCumedCagliari2024);
      return (
        <FormContainer />
      );
    }
    case 'cumed-piemonte': {
      const FormContainer = withForm(FormCumedPiemonte);
      return (
        <FormContainer />
      );
    }
    case 'diventa-volontario': {
      const FormContainer = withForm(FormDiventaVolontario);
      return (
        <FormContainer />
      );
    }
    case 'generico': {
      const FormContainer = withForm(FormGenerico);
      return (
        <FormContainer />
      );
    }
    case 'media-giornalisti': {
      const FormContainer = withForm(FormMediaGiornalisti);
      return (
        <FormContainer />
      );
    }
    case 'musei-biblioteche': {
      const FormContainer = withForm(FormMuseiBiblioteche);
      return (
        <FormContainer />
      );
    }
    case 'ospedali-amici': {
      const FormContainer = withForm(FormOspedaliAmici);
      return (
        <FormContainer />
      );
    }
    case 'scuola-proposte-educative': {
      const FormContainer = withForm(FormScuolaProposteEducative);
      return (
        <FormContainer />
      );
    }
    case 'miur': {
      const FormContainer = withForm(FormUnicefMiur);
      return (
        <FormContainer />
      );
    }
    case 'promoter-sociale': {
      const FormContainer = withForm(FormPromoterSociale);
      return (
        <FormContainer />
      );
    }
    default:
      return (
        <></>
      );
  }
};

export default FormContainer;
