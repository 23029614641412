import React, {
  useLayoutEffect, useState, useRef, useEffect
} from 'react';
import Icon from '../Icons';

const Accordion = ({
  title, children, isActive, handleClick
}) => {
  const contentRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [maxHeight, setMaxHeight] = useState(5000);

  useLayoutEffect(() => {
    if (contentRef.current.scrollHeight > 0) {
      setMaxHeight(contentRef.current.scrollHeight);
    }
  }, []);

  useEffect(() => {
    if (isActive !== 'undefined') {
      setIsOpen(isActive);
    }
  }, [isActive]);

  const handleOpen = () => {
    if (handleClick) {
      handleClick();
    }
    setIsOpen(!isOpen);
  };

  return (
    <div className={`accordion ${isOpen ? 'accordion--open' : ''}`}>
      <div className="accordion__wrapper">
        <button type="button" className="accordion__btn" onClick={() => handleOpen(!isOpen)}>
          <span className="accordion__title heading-xs">{title}</span>
          <Icon className="accordion__icon icon--24" path="arrow-small-down" />
        </button>
        <div ref={contentRef} className="accordion__content" style={{ maxHeight: `${isOpen ? maxHeight : 0}px` }}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
