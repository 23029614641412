/* eslint-disable */
import React, { useState } from "react"
import { useForm, Controller } from 'react-hook-form';
import TextField from '../TextFieldCustom';
import TextArea from '../TextArea';
import Select from '../Select';
import Button from '../Button';
import { useSubmitForm } from '../../hooks/hook.submit-form';
import piattaformeDivulgazione from '../../assets/piattaforme-divulgazione.json';
import { useGaEvent } from "../../hooks/hooks.ga-events";

const FormPromoterSociale = ({ setShowThankYouPage, disclaimer }) => {
  const {
    handleSubmit,
    register,
    errors,
    control,
  } = useForm({
    mode: 'onFocus'
  });

  const [loadingOnSubmit, setLoadingOnSubmit] = useState({
    label: 'Invia',
    disabled: false
  });

  const onSubmit = (data) => {
    setLoadingOnSubmit({
        label: 'Sto Inviando',
        disabled: true
      }
    );
    useSubmitForm(
      data,
      528,
      () => {
        setShowThankYouPage(true)
        useGaEvent('candidatura', 'form_completato');
      },
      () => {
        setLoadingOnSubmit({
            label: 'Invia',
            disabled: false
          }
        );
        alert('Ops, errore di rete, ritenta l\'invio');
      }
    );
  };

  return (
    <form noValidate className="form-group" onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group__container">
        <h3 className="form-group__title heading-md">Candidatura</h3>

        {/** NAME */}
        <TextField
          className="form-group__textfield"
          type="text"
          name="NOME"
          label="Nome"
          error={errors['NOME'] ? errors['NOME'].message : ''}
          required
          inputRef={register({
            required: 'Campo obbligatorio'
          })}
        />

        {/** SURNAME */}
        <TextField
          className="form-group__textfield"
          type="text"
          label="Cognome"
          name="COGNOME"
          error={errors['COGNOME'] ? errors['COGNOME'].message : ''}
          required
          inputRef={register({
            required: 'Campo obbligatorio'
          })}
        />

        {/** DATE OF BITH */}
        <TextField
          type="text"
          name="DATADINASCITA"
          label="Data di nascita (es: gg/mm/aaaa)"
          className="form-group__textfield"
          error={errors['DATADINASCITA'] ? errors['DATADINASCITA'].message : ''}
          required
          inputRef={register({
            required: 'Campo obbligatorio',
            pattern: {
              value: /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/,
              message: "Il formato non corrisponde"
            }
          })}
        />

        {/** Birthplace */}
        <TextField
          type="text"
          name="LUOGODINASCITA"
          label="Luogo di nascita"
          className="form-group__textfield"
          error={errors['LUOGODINASCITA'] ? errors['LUOGODINASCITA'].message : ''}
          inputRef={register()}
        />

        {/** Study */}
        <TextField
          type="text"
          label="Titolo di studio"
          name="TITOLODISTUDIO"
          className="form-group__textfield"
          error={errors['TITOLODISTUDIO'] ? errors['TITOLODISTUDIO'].message : ''}
          inputRef={register({
            required: 'Campo obbligatorio'
          })}
        />

         {/** TELEPHONE */}
         <TextField
          type="text"
          name="CELLULARE"
          label="Cellulare"
          className="form-group__textfield"
          error={errors['CELLULARE'] ? errors['CELLULARE'].message : ''}
          required
          inputRef={register({
            required: 'Campo obbligatorio',
            minLength: {
              value: 5,
              message: 'Il numero deve essere almeno di 5 cifre',
            },
            pattern: {
              value: /[+0-9]+/,
              message: 'Il formato non corrisponde'
            }
          })}
        />

        {/** EMAIL */}
        <TextField
          type="text"
          name="EMAIL"
          label="E-mail"
          className="form-group__textfield"
          error={errors['EMAIL'] ? errors['EMAIL'].message : ''}
          required
          inputRef={register({
            required: 'Campo obbligatorio',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Formato email non valido'
            }
          })}
        />
        {/** CITY */}
        <TextField
          type="text"
          label="Indica la città in cui ti candidi"
          name="CITTCANDIDATURA"
          className="form-group__textfield"
          required
          error={errors['CITTCANDIDATURA'] ? errors['CITTCANDIDATURA'].message : ''}
          inputRef={register({
            required: 'Campo obbligatorio'
          })}
        />
         {/** Platform */}
         <Controller
          control={control}
          name="CANALECONOSCENZA"
          render={({ onChange }) => (
            <Select
              className="form-group__select form-group__select--nowrap"
              label="Come ne sei venuto a conoscenza?"
              name="CANALECONOSCENZA"
              options={piattaformeDivulgazione}
              handleChange={(value) => onChange(value)}
              error={errors['CANALECONOSCENZA'] ? errors['CANALECONOSCENZA'].message : ''}
            />
          )}
        />

        {/** NOTES */}
        <TextArea
          label="Quali sono i tuoi punti di forza che farebbero di te un/una ottimo/a promoter sociale?"
          className="textarea form-group__textarea"
          name="TESTOLIBERO"
          inputRef={register({
            pattern: {
              required: false,
              maxLength: {
                  value: 300,
                  message: 'Il testo deve contenere massimo 300 caratteri',
              },
            }
          })}
        />

        {/** PRIVACY POLICY */}
        <div className="paragraph-small" dangerouslySetInnerHTML={{__html: disclaimer}} />
        <div className="form-group__privacy">
          {/** SUBMIT BUTTON */}
          <Button type="submit" kind="primary" disabled={loadingOnSubmit.disabled} label={loadingOnSubmit.label} color="brand" />
        </div>
      </div>
    </form>
  );
};

FormPromoterSociale.defaultProps = {
  disclaimer:
    '          <strong>Informativa per chi si candida a una posizione nella nostra organizzazione </strong>\n' +
    '\n' +     
    '          <p>Ai sensi degli artt. 13 e 14 del Regolamento UE 679/2016 (di seguito, “GDPR”), questa informativa descrive le modalità di trattamento dei dati personali di coloro che decidono di candidarsi a una posizione nella nostra organizzazione, sia che lo facciano direttamente (ad es. mediante l’invio della candidatura sul nostro sito web o a mezzo e-mail) o tramite terzi (ad es. attraverso le agenzie per il lavoro che ci trasmettono le candidature e i CV di candidati). </p>' +
    '          <p>Titolare del trattamento dei dati è il Comitato Italiano per l’UNICEF Fondazione ETS, Via Palestro 68, 00185 Roma (RM). \n' +
    '            I dati personali (dati di identificazione, contatti telefonici e e-mail, indirizzo del domicilio abituale e/o di residenza, immagini fotografiche,\n' +
    '            informazioni sul titolo di studio e formazione, dati sulla prestazione lavorativa, informazioni sull’esperienza professionale e gli altri ulteriori dati necessari all’eventuale instaurazione e \n' +
    '            gestione di un rapporto contrattuale da Lei forniti spontaneamente ai fini della selezione, compresi quelli presenti nel CV,\n' +
    '            eventuali informazioni sullo stato di salute necessarie ai fini dell’instaurazione del rapporto di lavoro ) saranno trattati per le seguenti finalità: </p>\n' +
    '          <ul>\n' +
    '            <li>a) <strong>Selezione del personale </strong></li>\n' +
    '            <li>b) Eventuale <strong>gestione del rapporto di lavoro</strong> da instaurare </li>\n' +
    '            <li>c) <strong>Ottemperanza</strong> a norme di legge o regolamento</li>\n' +
    '          </ul> <br>\n' +
    '          <p>I dati e le informazioni da Lei forniti spontaneamente sono trattati ai sensi dell’art. 111-bis del D. Lgs. n. 196/2003,\n' +
    '             come modificato dal D.lgs. n. 101/2018 (Codice Privacy). Per tutte le finalità il conferimento dei dati è obbligatorio ed è ammesso \n' +
    '             <strong>senza il consenso dell’interessato</strong> ai sensi dell’art. 6 comma 1, lett. b) e c) del GDPR.\n' +
    '             La mancata comunicazione dei dati contrassegnati come obbligatori comporterà l’impossibilità per il Titolare del trattamento di considerare la Sua candidatura ai fini della selezione.</p>\n' +
    '          <p>Per la finalità di cui al punto a) i dati saranno conservati per un <strong>tempo massimo pari a 18 mesi</strong> dopo la chiusura della selezione di riferimento.\n' +
    '             Per le finalità di cui ai punti b) e c) per il periodo consentito dalla legge per esigenze di tutela legale.</p>\n' +
    '          <p>Per i predetti fini saranno conservati anche successivamente, laddove leggi, regolamenti o normativa comunitaria ne determinino\n' +
    '             la conservazione per tempi differenti. Inoltre, saranno conservati <strong>in caso di imposizione</strong> da parte delle Forze dell’Ordine, della Magistratura e Organi di Controllo e per far valere o difendere diritti in sede giudiziaria.</p>\n' + 
    '<p>In tutte le fasi del trattamento, il Titolare assicura il pieno rispetto delle prescrizioni vincolanti contenute nel GDPR e previste dalla normativa nazionale vigente in materia di privacy. La raccolta dei dati, oltre che presso di Lei, viene svolta attingendo da archivi pubblici e privati nel rispetto dei diritti di accesso agli stessi, nonché tramite agenzie per il lavoro, che agiscono in qualità di autonomi titolari del trattamento.</p>' + 'L’organizzazione dei dati avviene, oltre che su supporto informatico, anche in forma cartacea. I dati saranno trattati e conservati presso gli uffici del Titolare, presso i sistemi dei soggetti che operano quali responsabili del trattamento di quest’ultima secondo accordi conformi all’art. 28 GDPR, in Italia e in ogni caso all’interno dell’Unione Europea. Qualora si rendesse necessario il trasferimento di dati personali anche al di fuori dell’Unione Europea, ciò avverrà sulla base di una decisione di adeguatezza della Commissione o delle altre garanzie adeguate previste dagli artt. 46 e ss. del GDPR. </p>' + 
    '          <p>Le <strong>persone autorizzate al trattamento</strong> sono gli addetti all’Ufficio Risorse Umane, il responsabile dell’unità organizzativa a cui si riferisce la selezione di riferimento, dei sistemi informativi e di sicurezza dei dati.</p>\n' +
    '          <p>I dati potranno anche essere comunicati, esclusivamente per le finalità sopra descritte, a <strong>terzi</strong>  nominati "Responsabili esterni" per iscritto ai sensi dell’art. 28 GDPR.(es. consulente del lavoro, studio di elaborazione cedolini, fornitori del sistema di rilevazione presenze ecc.).</p>\n' +
    '          <p>Ai sensi degli artt. 15-22, GDPR, scrivendo all’e-mail <a mailto="infoprivacy@unicef.it">infoprivacy@unicef.it</a>  si può richiedere <strong>l’elenco completo e aggiornato dei responsabili del trattamento</strong>, nonché esercitare i diritti di accesso, rettifica, cancellazione, limitazione del trattamento dei dati e portabilità dei dati.</p>\n' +
    '          <p>Si ha inoltre <strong>diritto di presentare reclamo</strong> all’autorità di controllo (Garante per la protezione dei dati personali – <a href="https://www.garanteprivacy.it/" target="_blank">www.garanteprivacy.it</a>) per far valere i propri diritti.</p>\n' +
    '          <p>Il Data Protection Officer del Comitato Italiano per l’UNICEF - Fondazione ETS è contattabile all’e-mail dpo@unicef.it per informazioni sul trattamento dei dati.</p>\n' +
    '\n' +
    '          Informativa completa al sito <a href="https://www.unicef.it/note-sulla-privacy">www.unicef.it/privacy</a>\n'
}

export default FormPromoterSociale;
