/* eslint-disable */
import React, {useState} from 'react';
import { useForm } from 'react-hook-form';
import TextField from '../TextFieldCustom';
import CheckBox from '../CheckboxCustom';
import Button from '../Button';
import { useSubmitForm } from '../../hooks/hook.submit-form';

const FormMediaGiornalisti = ({ setShowThankYouPage, disclaimer }) => {
  const {
    handleSubmit,
    register,
    errors,
    watch,
  } = useForm({
    mode: 'onFocus'
  });

  const [loadingOnSubmit, setLoadingOnSubmit] = useState({
    label: 'Invia',
    disabled: false
  });

  const onSubmit = (data) => {
    setLoadingOnSubmit({
        label: 'Sto Inviando',
        disabled: true
      }
    );
    useSubmitForm(
      data,
      383,
      () => setShowThankYouPage(true),
      () => {
        setLoadingOnSubmit({
            label: 'Invia',
            disabled: false
          }
        );
        alert('Ops, errore di rete, ritenta l\'invio');
      }
    );
  };

  const freelance = watch('freelance');
  const activity = watch('Attivita');

  const attivitaCheckboxGroup = [
    {
      label: 'Giornalista',
      value: 'Giornalista'
    },
    {
      label: 'Fotografo',
      value: 'Fotografo'
    },
    {
      label: 'Videomaker',
      value: 'Videomaker'
    },
    {
      label: 'Altro',
      value: 'Altro'
    }
  ];

  const redazioneCheckboxGroup = [
    {
      label: 'Esteri',
      value: 'Esteri'
    },
    {
      label: 'Sociale',
      value: 'Sociale'
    },
    {
      label: 'Interni',
      value: 'Interni'
    },
    {
      label: 'Economia',
      value: 'Economia'
    },
    {
      label: 'Salute',
      value: 'Salute'
    },
    {
      label: 'Cultura',
      value: 'Cultura'
    },
    {
      label: 'Spettacoli',
      value: 'Spettacoli'
    },
    {
      label: 'Sport',
      value: 'Sport'
    },
    {
      label: 'Cronaca locale',
      value: 'Cronaca locale'
    },
    {
      label: 'Web/Nuove tecnologie',
      value: 'Web/nuove tecnologie'
    },
    {
      label: 'Scuola/università',
      value: 'Scuola/università'
    },
    {
      label: 'Altro',
      value: 'Altro'
    }
  ];

  return (
    <form noValidate className="form-group form-media" onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group__container">
        <h3 className="form-group__title heading-md">Media - Giornalisti</h3>

        {/** NAME */}
        <TextField
          className="form-group__textfield"
          type="text"
          name="Nome"
          label="Nome"
          error={errors['Nome'] ? errors['Nome'].message : ''}
          required
          inputRef={register({
            required: 'Campo obbligatorio',
            minLength: {
              value: 3,
              message: 'Il nome deve essere almeno di 3 cifre',
            }
          })}
        />

        {/** SURNAME */}
        <TextField
          className="form-group__textfield"
          type="text"
          label="Cognome"
          name="Cognome"
          error={errors['Cognome'] ? errors['Cognome'].message : ''}
          required
          inputRef={register({
            required: 'Campo obbligatorio',
            minLength: {
              value: 3,
              message: 'Il cognome deve essere almeno di 3 cifre',
            }
          })}
        />

        {/** ACTIVITIES */}
        <div className="form-group__multiselect">
          <p className="form-group__title-multiselect paragraph">Attività</p>
          {
            attivitaCheckboxGroup.map((checkbox) => {
              return (
                <CheckBox
                  key={checkbox.value}
                  variant="white"
                  name="Attivita"
                  label={checkbox.label}
                  value={checkbox.value}
                  className="form-group__checkbox"
                  inputRef={register()}
                />
              );
            })
          }
          {
            activity && activity.indexOf('Altro') >= 0 && (
              <TextField
                type="text"
                label="Altro"
                name="Altro"
                required
                error={errors['Altro'] ? errors['Altro'].message : ''}
                className="form-group__textfield"
                inputRef={register({
                  required: 'Campo obbligatorio',
                  minLength: {
                    value: 3,
                    message: 'Il valore deve contenere almeno 3 caratteri',
                  }
                })}
              />
            )
          }
        </div>

        <div className="form-group__multiselect">
          <p className="form-group__title-multiselect paragraph testata__title">Testata</p>
        </div>
        {
          !freelance
          && (
            <TextField
              type="text"
              label="Nome della testata"
              name="Testata"
              required
              error={errors['Testata'] ? errors['Testata'].message : ''}
              className="form-group__textfield"
              inputRef={register({
                required: 'Campo obbligatorio',
                minLength: {
                  value: 3,
                  message: 'Il valore deve contenere almeno 5 caratteri',
                }
              })}
            />
          )
        }
        <CheckBox
          variant="white"
          name="freelance"
          label="Freelance"
          className="form-group__textfield form-group__freelance"
          required
          inputRef={register()}
          error={errors['freelance'] ? errors['freelance'].message : ''}
        />

        {/** EDITORIAL BOARD */}
        <div className="form-group__multiselect">
          <p className="form-group__title-multiselect paragraph">Redazione</p>

          {
            redazioneCheckboxGroup.map((checkbox) => {
              return (
                <CheckBox
                  key={checkbox.value}
                  variant="white"
                  name="Redazione"
                  label={checkbox.label}
                  value={checkbox.value}
                  className="form-group__checkbox"
                  error={errors['Redazione'] ? errors['Redazione'].message : ''}
                  inputRef={register()}
                />
              );
            })
          }

        </div>

        {/** EMAIL */}
        <TextField
          className="form-group__textfield"
          type="text"
          label="Email"
          name="Email"
          required
          error={errors['Email'] ? errors['Email'].message : ''}
          inputRef={register({
            required: 'Campo obbligatorio',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Formato email non valido'
            }
          })}
        />

        {/** TELEPHONE */}
        <TextField
          type="text"
          label="Telefono"
          name="Telefono"
          className="form-group__textfield"
          inputRef={register({
            minLength: {
              value: 5,
              message: 'Il numero deve essere almeno di 5 cifre',
            },
            pattern: {
              value: /[+0-9]+/,
              message: 'Il formato non corrisponde'
            }
          })}
        />

        {/** PRIVACY POLICY */}
        <div className="paragraph-small" dangerouslySetInnerHTML={{ __html: disclaimer }} />
        <div className="form-group__privacy">
          {/** SUBMIT BUTTON */}
          <Button type="submit" kind="primary" disabled={loadingOnSubmit.disabled} label={loadingOnSubmit.label} color="brand" />
        </div>
      </div>
    </form>
  );
};

FormMediaGiornalisti.defaultProps = {
  disclaimer:
    '          <strong>Informativa ex art.13, GDPR</strong>\n'
    + '\n'
    + '          <p>Titolare del trattamento dei dati è il Comitato Italiano per l’UNICEF - Fondazione ONLUS , Via Palestro 68, 00185 Roma (RM).\n'
    + '            I dati personali saranno trattati con modalità prevalentemente elettroniche per le seguenti finalità:</p>\n'
    + '          <ul>\n'
    + '            <li>a) Inviare il materiale richiesto e/o dare riscontro alle richieste avanzate per l’adesione al Percorso di interesse</li>\n'
    + '            <li>b) Ottemperare a norme di legge o regolamento</li>\n'
    + '            <li>c) Fornire informazioni su iniziative/programmi di sensibilizzazione e promozione dei diritti dei bambini</li>\n'
    + '          </ul>\n'
    + '          <p>Per le finalità ai punti a) e b), il trattamento dei dati è ammesso senza il consenso dell’interessato ai sensi dell’art. 6 comma 1, lett. b) e c)\n'
    + '            GDPR; i dati saranno conservati per il tempo necessario a rispondere alle richieste e per eventuali contatti di approfondimento che permettano di\n'
    + '            valutare se aderire all’ iniziativa di interesse; in ogni caso per il periodo consentito dalla legge per esigenze di tutela legale. Per le finalità al\n'
    + '            punto c) il trattamento è ammesso ai sensi dell’art. 6 comma 1 lettera f) GDPR (“legittimo interesse”); i dati verranno conservati per il periodo\n'
    + '            consentito dalla legge per esigenze di tutela legale; la conservazione può essere di tempo inferiore se non si ha più\n'
    + '            interesse ad essere informati e lo si comunica con le modalità sotto riportate.</p>\n'
    + '          <p>Per i predetti fini saranno conservati anche successivamente, laddove leggi, regolamenti o normativa comunitaria ne determinino\n'
    + '            la conservazione per tempi differenti. Inoltre, saranno conservati in caso di imposizione da parte delle Forze dell’Ordine,\n'
    + '            della Magistratura e Organi di Controllo e per far valere o difendere diritti in sede giudiziaria. Le persone autorizzate al trattamento sono\n'
    + '            gli addetti all’amministrazione e organizzatori dell’iniziativa, alle attività istituzionali e statutarie, dei sistemi informativi e di sicurezza dei dati.</p>\n'
    + '          <p>Ai sensi degli artt. 15-22, GDPR, scrivendo all’e-mail <a href="mailto:infoprivacy@unicef.it">infoprivacy@unicef.it</a>,\n'
    + '            si può richiedere l’elenco completo e aggiornato dei responsabili del trattamento, nonché esercitare i diritti di consultazione,\n'
    + '            modificazione, di cancellazione e oblio, limitazione del trattamento dei dati, portabilità dei dati o opporsi al loro trattamento. Inoltre,\n'
    + '            l’interessato ha diritto di presentare reclamo all’autorità di controllo – per l’Italia è il Garante per la protezione dei dati personali\n'
    + '            – <a href="https://www.garanteprivacy.it">www.garanteprivacy.it</a>, per far valere i propri diritti. Il Data Protection Officer\n'
    + '            è contattabile all’e-mail <a href="mailto:dpo@unicef.it">dpo@unicef.it</a>, per informazioni sul trattamento dei dati.</p>\n'
    + '\n'
    + '          Informativa completa al sito <a href="https://www.unicef.it/note-sulla-privacy">www.unicef.it/privacy</a>\n'
};

export default FormMediaGiornalisti;
