import React from 'react';

// AccordionWithRichText
const AccordionRichText = ({ content }) => {
  return (
    content && <div className="accordion-paragraph paragraph-small" dangerouslySetInnerHTML={{ __html: `${content}` }} />
  );
};

AccordionRichText.defaultPros = {
  content: 'Puoi attivare una donazione regolare continuativa tramite domiciliazione \n'
    + 'bancaria/postale o carta di credito.\n'
    + 'Per aderire compila questo form'
};

export default AccordionRichText;
