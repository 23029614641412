/* eslint-disable no-unneeded-ternary */
import React from 'react';
import Img from 'gatsby-image';
import Container from '../Container';

const ImageMedium = ({
  caption, image, marginTop, marginBottom, className, isLocalNews
}) => {
  const classes = `image-medium${className ? ` ${className}` : ''}`;
  return (
    <Container
      className={classes}
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      <div className="image-medium__wrapper">
        <figure className="image-medium__container">
          {image && (
            <Img
              fluid={image.fluid}
              alt={image.alt}
              className="image-medium__photo"
            />
          )}
          {(!isLocalNews && caption) && (
            <figcaption className="image-medium__caption-container">
              <span className="image-medium__caption caption">{caption}</span>
            </figcaption>
          )}
        </figure>
      </div>
      { isLocalNews && caption && (
        <div className="image-medium__caption-container">
          <p className="image-medium__caption caption">{caption}</p>
        </div>
      )}
    </Container>
  );
};

export default ImageMedium;
