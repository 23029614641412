import React from 'react';
import Container from '../Container';
import placeholderImage from '../../images/icon-banner-placeholder.png';
import { InternalLink } from '../Link';
import Button from '../Button';

const ThankYou = ({
  marginTop,
  marginBottom,
  image,
  title,
  description,
  labelButtonHome,
  labelButtonForm,
  setShowThankYouPage
}) => {
  return (
    <Container
      className="thank-you"
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      <div className="thank-you__wrapper">
        <div className="thank-you__container">
          <figure className="thank-you__icon">
            <img src={image} className="thank-you__image" alt="icon" />
          </figure>
          <span className="thank-you__title heading-md">{title}</span>
          {description !== '' && <p className="thank-you__description paragraph">{description}</p>}
          <div className="thank-you__btn-container">
            <InternalLink
              label={labelButtonHome}
              color="brand"
              kind="primary"
              url="/"
            />
            <Button
              label={labelButtonForm}
              color="brand"
              kind="primary"
              handleClick={() => setShowThankYouPage(false)}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

ThankYou.defaultProps = {
  image: `${placeholderImage}`,
  labelButtonHome: 'Torna alla home',
  urlHome: '',
  labelButtonForm: 'Compila di nuovo',
  urlForm: ''
};

export default ThankYou;
