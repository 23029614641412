import React, { useRef } from 'react';
import Img from 'gatsby-image';
import Icon from '../Icons';
import { ButtonLink } from '../Link';

const PeopleModal = ({
  people, closeModal, modal, hasLogos
}) => {
  const modalRef = useRef(null);
  return (
    people && people.map((person, index) => {
      const {
        image, role, name, biography, title, content, url, isCircleImage
      } = person;
      return (
        <div key={`${person.id}-${index}`} className={`people-modal ${index === modal ? 'people-modal--open' : ''}`}>
          <div className="people-modal__mask" />
          <div className="people-modal__wrapper">
            <div ref={modalRef} className="people-modal__container">
              <button type="button" className="people-modal__close" onClick={() => closeModal()}>
                <Icon className="people-modal__icon icon--24" path="close" />
              </button>
              <div className="people-modal__content">
                {
                  (image && !hasLogos) && (
                    <Img
                      fluid={image.fluid}
                      className={`people-modal__image ${isCircleImage ? 'is-circle' : ''}`}
                      alt={image.alt}
                    />
                  )
                }
                <p className="people-modal__text-container">
                  {role && <span className="people-modal__label label">{role}</span>}
                  {(name || title) && <span className="people-modal__title heading-md">{name || title}</span>}
                </p>
              </div>
              <div className="people-modal__paragraph-container">
                {
                  (biography || content) && (
                    <div className="people-modal__paragraph paragraph" dangerouslySetInnerHTML={{ __html: `${biography || content}` }} />
                  )
                }
                {
                  url && (
                    <ButtonLink
                      url={url}
                      color="brand"
                      kind="secondary"
                      label="Scopri di più"
                    />
                  )
                }
              </div>
            </div>
          </div>
        </div>
      );
    })
  );
};

export default PeopleModal;
