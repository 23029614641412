import React, { useState, useRef, useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import { CSSRulePlugin } from 'gsap/CSSRulePlugin';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import Button from '../Button';
import Container from '../Container';
import NavigationThirdLevel from '../NavigationThirdLevel';

gsap.registerPlugin(CSSRulePlugin);
gsap.registerPlugin(ScrollToPlugin);

/**
 * Component to render paragraphs in the visual composer
 * or in a static page
 * @param {RichText} content
 * @param {String} title
 * @param {RichText} contentAccordion
 * @param {Boolean} dropcap
 */
const Paragraph = ({
  content, subtitle, dropcap, contentAccordion,
  marginTop, marginBottom, colorTheme, numCols,
  treeParent, treeChildren, root, activeNavigationSidebar, parentPathname, pageSlug
}) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [maxHeight, setMaxHeight] = useState(100);
  const accordionContentRef = useRef(null);

  const htmlParagraphContent = { __html: content };
  const paragraphAccordionContent = { __html: contentAccordion };
  useLayoutEffect(() => {
    if (accordionContentRef.current && accordionContentRef.current.scrollHeight > 0) {
      setMaxHeight(accordionContentRef.current.scrollHeight);
    }
  }, []);
  let label = '';

  if (colorTheme && colorTheme === 'titolo-nero') {
    label = '';
  } else if (colorTheme && colorTheme === 'titolo-azzurro') {
    label = subtitle;
  }

  useLayoutEffect(() => {
    const timeline = gsap.timeline();
    const oldOffset = window.pageYOffset;
    if (isAccordionOpen === true) {
      timeline
        .to(accordionContentRef.current, { height: maxHeight, duration: 0.3 })
        .to(window, { scrollTo: { y: oldOffset } }, 0);
    } else {
      timeline
        .to(accordionContentRef.current, { height: 0, duration: 0.3 });
    }
  }, [isAccordionOpen]);

  const handleOpenAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  const paragraphClasses = `paragraph-content
    ${subtitle ? 'paragraph--with-title' : ''}
    ${dropcap ? 'paragraph--with-dropcap' : ''}
    ${colorTheme === 'titolo-azzurro' ? 'paragraph--with-label' : ''}`.replace(/\n\s+/g, ' ');
  return (
    <Container
      className={paragraphClasses}
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      <div className="paragraph-content__wrapper">
        {
          activeNavigationSidebar && !root && (
            <NavigationThirdLevel
              parentPathname={parentPathname}
              labelParent={treeParent}
              menuItems={treeChildren}
              pageSlug={pageSlug}
            />
          )
        }
        <div className={`paragraph__container num-cols--${numCols}`}>
          {subtitle && !label && <h2 className="paragraph__title heading-md">{subtitle}</h2>}
          {label && <h2 className="paragraph__label text-color-brand label">{label}</h2>}
          {content && (<div className="paragraph__wrapper" dangerouslySetInnerHTML={htmlParagraphContent} />)}
          {(contentAccordion && content) && (
            <div className="paragraph-accordion">
              <div className="paragraph-accordion__wrapper">
                <div
                  ref={accordionContentRef}
                  className="paragraph-accordion__content"
                  dangerouslySetInnerHTML={paragraphAccordionContent}
                  // style={{ maxHeight: `${isAccordionOpen ? maxHeight : 0}px` }}
                />
              </div>
              <div className="paragraph-accordion__header">
                <Button
                  kind="secondary"
                  color="brand"
                  handleClick={() => handleOpenAccordion()}
                  label={`${isAccordionOpen ? 'Chiudi' : 'Leggi di più'}`}
                  icon={`${isAccordionOpen ? 'arrow-small-up' : 'arrow-small-down'}`}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

Paragraph.defaultProps = {
  accordionContent: ''
};

export default Paragraph;
