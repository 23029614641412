import React, {
  useState,
  useLayoutEffect,
  useRef,
  useEffect
} from 'react';
import Img from 'gatsby-image';
import Swiper, { Autoplay, Lazy } from 'swiper';
import { gsap } from 'gsap';
import { CSSRulePlugin } from 'gsap/CSSRulePlugin';
import Icon from '../Icons';
import useMatchMedia, { useCheckResize } from '../../hooks/match-media';
import Container from '../Container';
import Linked from '../Linked';

gsap.registerPlugin(CSSRulePlugin);

const swiperOptions = {
  slidesPerView: 'auto',
  centeredSlides: true,
  centeredSlidesBounds: true,
  autoplay: {
    delay: 4000,
    disableOnInteraction: true
  },
  lazy: false,
  spaceBetween: 20,
  grabCursor: true,
  initialSlide: 0,
  preloadImages: false,
  loop: true,
  breakpoints: {
    320: {
      spaceBetween: 20
    },
    768: {
      spaceBetween: 88
    },
    1024: {
      spaceBetween: 104
    },
    1366: {
      spaceBetween: 136
    },
    1536: {
      spaceBetween: 146
    },
  }
};

const SliderImageGalleryHome = ({
  marginTop, marginBottom, slides
}) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [mySwiper, setMySwiper] = useState(null);
  const [slideWidth, setSlideWidth] = useState(0);
  // const [slidesPerView, setSlidesPerView] = useState(1);
  const gridRef = useRef(null);
  const swiperRef = useRef(null);

  const handleResize = () => {
    if (gridRef.current) {
      /** Setting slide width based content width */
      const { clientWidth } = gridRef.current;
      setSlideWidth(clientWidth);
    }
  };
  useCheckResize(handleResize);

  useLayoutEffect(() => {
    setSlideWidth(gridRef.current.clientWidth);
  }, [gridRef.current]);

  useEffect(() => {
    /** Destroying swiper on unmount */
    return () => {
      if (mySwiper) {
        mySwiper.destroy(true, true);
      }
    };
  }, []);

  useLayoutEffect(() => {
    /** Mount swiper after getting width size */
    if (slideWidth && mySwiper == null) {
      Swiper.use(Autoplay).use(Lazy);
      const sw = new Swiper(swiperRef.current, {
        ...swiperOptions,
        on: {
          slideChange: (swiper) => {
            const { activeIndex, slides } = swiper;
            let realIndex = 0;
            if (slides[activeIndex]) {
              realIndex = +slides[activeIndex].getAttribute('data-swiper-slide-index') + 1;
            }
            setCurrentSlideIndex(realIndex);
          }
        }
      });
      setMySwiper(sw);
    }
  }, [slideWidth]);

  const sliderPagination = `${currentSlideIndex} / ${slides.length}`;
  return (
    <Container
      className="slider-image"
      marginBottom={marginBottom}
      marginTop={marginTop}
    >
      <div className="slider-image__overlay">
        <div className="slider-image__controllers">
          <button
            onClick={() => { mySwiper.slidePrev(); }}
            className="slider-image__control"
            type="button"
          >
            <Icon path="arrow-big-left" className="icon--40" />
          </button>
          <button
            onClick={() => { mySwiper.slideNext(); }}
            className="slider-image__control"
            type="button"
          >
            <Icon path="arrow-big-right" className="icon--40" />
          </button>
        </div>
      </div>
      <div ref={swiperRef} className="swiper-container">
        <div className="swiper-wrapper">
          {
            slides && slides.map((slide, index) => {
              const {
                id,
                title,
                label,
                url,
                image
              } = slide;
              return (
                <Slide
                  key={`${id}-${index}`}
                  slideWidth={slideWidth}
                  title={title}
                  label={label}
                  url={url}
                  image={image}
                />
              );
            })
          }
        </div>
      </div>
      <div className="slider-image__bottom-wrapper">
        <div ref={gridRef} className="slider-image__bottom-content">
          <ProgressBar activeIndex={currentSlideIndex} />
          <span className="label-small text-color-white slider-image__pagination">{sliderPagination}</span>
        </div>
      </div>
    </Container>
  );
};

const Slide = ({
  title, label, image, url, slideWidth
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const mediaToMatch = '(max-width: 767px)';
  useMatchMedia(setIsMobile, mediaToMatch);

  const setSlideStyle = () => {
    if (!isMobile) {
      return { width: slideWidth };
    }
    return {};
  };

  const slideStyle = setSlideStyle();
  return (
    <div className="swiper-slide slider-image__slide" style={slideStyle}>
      <Linked url={url} className="slider-image__slide-link">
        <Img
          className="slider-image__image"
          fluid={{
            ...image.fluid,
            aspectRatio: 16 / 9
          }}
          loading="eager"
          alt={image.alt}
        />
        <div className="label-small text-color-brand slider-image__slide-label">{label}</div>
        <h3 className="heading-sm text-color-white slider-image__slide-title">{title}</h3>
      </Linked>
    </div>
  );
};

const ProgressBar = ({ activeIndex }) => {
  const [timeline, setTimeline] = useState(null);
  const runner = useRef(null);

  useEffect(() => {
    if (timeline) {
      timeline.restart();
    }
  }, [activeIndex]);

  useLayoutEffect(() => {
    if (runner.current) {
      const tl = gsap.timeline();
      tl.fromTo(runner.current, { scaleX: 0 }, { scaleX: 1, duration: 4 });
      setTimeline(tl);
    }
  }, []);

  return (
    <div className="slider-image__progress-bar slider-image__progress-bar--active">
      <div ref={runner} className="slider-image__progress-runner" />
    </div>
  );
};

export default SliderImageGalleryHome;
