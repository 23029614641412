import React, {
  useState,
  useLayoutEffect,
  useRef,
  useEffect
} from 'react';
import Img from 'gatsby-image';
import Swiper from 'swiper';
import Icon from '../Icons';
import { useCheckResize } from '../../hooks/match-media';
import Container from '../Container';

const ImageGallery = ({ marginTop, marginBottom, images }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [mySwiper, setMySwiper] = useState(null);
  const [slideWidth, setSlideWidth] = useState(0);
  const gridRef = useRef(null);
  const swiperRef = useRef(null);

  const swiperOptions = {
    slidesPerView: 'auto',
    centeredSlides: true,
    spaceBetween: 20,
    grabCursor: true,
    initialSlide: 0,
    preloadImages: false,
    lazy: true,
    loop: true,
    breakpoints: {
      320: {
        spaceBetween: 10
      },
      768: {
        spaceBetween: 24
      },
    },
    on: {
      slideChange: (swiper) => {
        const { activeIndex, slides } = swiper;
        const realIndex = +slides[activeIndex].getAttribute('data-swiper-slide-index');
        setCurrentSlideIndex(realIndex);
      }
    }
  };

  useLayoutEffect(() => {
    setSlideWidth(gridRef.current.clientWidth);
  }, [gridRef.current]);

  useLayoutEffect(() => {
    /** Mount swiper after getting width size */
    if (slideWidth && mySwiper == null) {
      const swiper = new Swiper(swiperRef.current, swiperOptions);
      setMySwiper(swiper);
    }
  }, [slideWidth]);

  const handleResize = () => {
    if (gridRef) {
      /** Setting slide width based content width */
      const { clientWidth } = gridRef.current;
      setSlideWidth(clientWidth);
    }
  };
  useCheckResize(handleResize);

  useEffect(() => {
    if (mySwiper) {
      /** Regenerate loop everytime slideWidth changes */
      mySwiper.loopDestroy();
      mySwiper.loopCreate();
      mySwiper.update();
    }
  }, [mySwiper, slideWidth]);

  useEffect(() => {
    /** Destroying swiper on unmount */
    return () => {
      if (mySwiper) {
        mySwiper.destroy(true, true);
      }
    };
  }, []);

  const sliderPagination = `${currentSlideIndex + 1} / ${images.length}`;

  return (
    <Container
      className="image-gallery"
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      <div ref={swiperRef} className="swiper-container">
        <div className="swiper-wrapper">
          {images.map((image, index) => {
            const { fluid, alt, originalId } = image;
            return (
              <div key={`${originalId}-${index}`} className="swiper-slide" style={{ width: slideWidth }}>
                <div className="image-gallery__image">
                  <Img fluid={{ ...fluid, aspectRatio: 16 / 9 }} alt={alt} loading="eager" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="image-gallery__bottom-wrapper">
        <div ref={gridRef} className="image-gallery__bottom-content">
          <div className="image-gallery__description">
            <span className="image-gallery__pagination label-small">{sliderPagination}</span>
            <p className="image-gallery__caption caption">{images[currentSlideIndex].title}</p>
          </div>
          <div className="image-gallery__controllers">
            <button type="button" className="image-gallery__btn" onClick={() => mySwiper.slidePrev()}>
              <Icon className="icon--40" path="arrow-big-left" />
            </button>
            <button type="button" className="image-gallery__btn" onClick={() => mySwiper.slideNext()}>
              <Icon className="icon--40" path="arrow-big-right" />
            </button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ImageGallery;
