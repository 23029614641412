import React, { useEffect, useLayoutEffect, useState } from 'react';
import Container from '../Container';
import { ButtonLink } from '../Link';
import PeopleModal from '../PeopleModal';
import Button from '../Button';

const BannerText = ({
  colorTheme, title, content, url, marginTop, marginBottom, labelButton, hasModal, paragraph
}) => {
  // Functions to set ColorTheme
  const [theme, setTheme] = useState('3');
  const [colorButton, setColorButton] = useState('white');

  // Functions to render version with Modal
  const [modal, setModal] = useState(-1);
  const closeModal = () => {
    setModal(-1);
  };
  const modalContent = [
    {
      id: 0,
      role: title,
      title: content,
      content: paragraph
    }
  ];

  useEffect(() => {
    if (colorTheme === 'sfondo-nero-con-bottone-blu') {
      setColorButton('brand-on-black');
      setTheme('2');
    }
  }, [colorTheme]);

  useLayoutEffect(() => {
    if (modal >= 0) {
      document.querySelector('body').classList.add('no-scroll');
    } else {
      document.querySelector('body').classList.remove('no-scroll');
    }
  }, [modal]);

  return (
    <Container
      className={`banner-text banner-text--theme-v${theme}`}
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      <div className="banner-text__wrapper">
        <div className="banner-text__content">
          <div className="banner-text__content-wrapper">
            <span className="banner-text__title label-small">{title}</span>
            <h2 className="banner-text__text-content heading-md">{content}</h2>
          </div>
          {
            !hasModal ? (
              <ButtonLink
                kind="primary"
                label={labelButton ? labelButton.value : 'Scopri di più'}
                color={colorButton}
                className="banner-text__discover-btn"
                url={url}
              />
            ) : (
              <Button
                className="banner-text__discover-btn"
                label={labelButton ? labelButton.value : 'Scopri di più'}
                handleClick={() => setModal(0)}
                color={colorButton}
                kind="primary"
              />
            )
          }
        </div>
        {hasModal && (
          <PeopleModal people={modalContent} closeModal={closeModal} modal={modal} />
        )}
      </div>
    </Container>
  );
};

export default BannerText;
