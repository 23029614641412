import React, {
  useLayoutEffect, useRef, useState, useEffect
} from 'react';
import { gsap } from 'gsap';
import { CSSRulePlugin } from 'gsap/CSSRulePlugin';

gsap.registerPlugin(CSSRulePlugin);

const ProgressBar = ({ activeIndex, isActive, duration }) => {
  const [timeline, setTimeline] = useState(null);
  const runner = useRef(null);

  useEffect(() => {
    if (timeline) {
      timeline.restart();
    }
  }, [activeIndex]);

  useLayoutEffect(() => {
    if (runner.current) {
      const tl = gsap.timeline();
      if (isActive) {
        tl.fromTo(runner.current, { scaleX: 0 }, { scaleX: 1, duration });
      }
      setTimeline(tl);
    }
  }, []);

  return (
    <div className="progress-bar">
      <div ref={runner} className="progress-bar__runner" />
    </div>
  );
};

ProgressBar.defaultProps = {
  duration: 4
};

export default ProgressBar;
