import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const CheckboxCustom = ({
  label, className, inputRef, name, value
}) => {
  const classes = `styled-checkbox ${className || ''}`;

  return (
    <FormControlLabel
      className={classes}
      control={<Checkbox />}
      label={label}
      name={name}
      inputRef={inputRef}
      value={value}
    />
  );
};

export default CheckboxCustom;
