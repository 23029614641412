import React, { useState, useLayoutEffect, useRef } from 'react';
import Img from 'gatsby-image';
import Swiper from 'swiper';
import Logo from '../Logo';
import Container from '../Container';

/**
 * Slider used to list story
 */

const swiperOptions = {
  slidesPerView: 'auto',
  spaceBetween: 80,
  grabCursor: true,
  preloadImages: true,
  lazy: true,
  updateOnWindowResize: true,
  breakpoints: {
    320: {
      spaceBetween: 24
    },
    768: {
      spaceBetween: 68,
      slidesOffsetBefore: 40,
      slidesOffsetAfter: 305,
    },
    1024: {
      spaceBetween: 64,
      slidesOffsetBefore: 48,
      slidesOffsetAfter: 285,
    },
    1366: {
      spaceBetween: 80,
      slidesOffsetBefore: 55,
      slidesOffsetAfter: 420,
    },
    1536: {
      spaceBetween: 80,
      slidesOffsetBefore: 55,
      slidesOffsetAfter: 450,
    },
  }
};

const SliderStory = ({
  label, title, description, events, marginTop, marginBottom
}) => {
  const [mySwiper, setMySwiper] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperContainer = useRef(null);

  useLayoutEffect(() => {
    if (swiperContainer.current && mySwiper === null) {
      const swiper = new Swiper(swiperContainer.current, {
        ...swiperOptions,
        on: {
          slideChange: (swiper) => {
            setActiveIndex(swiper.activeIndex);
          }
        }
      });
      setMySwiper(swiper);
    }
  }, []);

  const slideTo = (index) => {
    mySwiper.slideTo(index);
  };

  return (
    <Container
      className="story story--theme-v1"
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      <div className="story__container">
        <div className="story__header">
          <div className="story__text-container">
            {label && <span className="label-small text-color-brand">{label}</span>}
            {title && <h3 className="heading-sm story__title">{title}</h3>}
            {
              description && (
                <p className="paragraph-small story__description">
                  {description}
                </p>
              )
            }
          </div>
        </div>
        <div className="story__slider">
          <div ref={swiperContainer} className="swiper-container">
            <div className="swiper-wrapper">
              {events && events.map((event) => {
                const {
                  id, date, image, description
                } = event;
                return (
                  <CardStory
                    key={id}
                    cards={events}
                    date={date}
                    title={description}
                    image={image}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="story__timeline">
        <Timeline events={events} slideTo={slideTo} activeIndex={activeIndex} />
        <span className="story__line" />
      </div>
      <Logo className="logo--white story__logo" />
    </Container>
  );
};

const Timeline = ({ events, slideTo, activeIndex }) => {
  return (
    <div className="timeline">
      {
        events.map((slide, index) => {
          const {
            id
          } = slide;
          const isActive = index === activeIndex;
          const timelineClasses = `timeline__timekeeper ${isActive ? 'timeline__timekeeper--active' : ''}`;
          return (
            <div key={id} className={timelineClasses}>
              <button className="timeline__button" type="button" onClick={() => slideTo(index)}>
                <span className="timeline__bullet" />
                <span className="heading-xs timeline__date text-color-dark-grey">{slide.date}</span>
              </button>
            </div>
          );
        })
      }
      <span className="timeline__line" />
    </div>
  );
};

const CardStory = ({ image, date, title }) => {
  return (
    <article className="swiper-slide card-story">
      {image && (
        <Img
          fluid={{
            ...image.fluid,
            aspectRatio: 16 / 9
          }}
          alt={image.alt}
          className="card-story__image"
        />
      )}
      {date && <span className="card-story__date heading-xl">{date}</span>}
      {title && (
        <h3 className="card-story__title heading-sm" dangerouslySetInnerHTML={{ __html: `${title}` }} />
      )}
    </article>
  );
};

export default SliderStory;
