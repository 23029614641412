import React from 'react';
import Container from '../Container';
import AnchorLink from '../Link';

const Download = ({
  numCols,
  label,
  listDocument,
  labelButton,
  marginTop,
  marginBottom,
}) => {
  const classNameWidthContent = `download__internal-container num-cols--${numCols}`;
  return (
    <Container
      className="download"
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      <div className="download__wrapper">
        <div className={classNameWidthContent}>
          <span className="download__label label">{label}</span>
          {
            listDocument && listDocument.map((itemDocument) => {
              const {
                title, format, size, url, originalId
              } = itemDocument;
              return (
                <DownloadContent
                  key={originalId}
                  title={title}
                  format={format}
                  size={size}
                  labelButton={labelButton}
                  url={url}
                />
              );
            })
          }
        </div>
      </div>
    </Container>
  );
};

const DownloadContent = ({
  title, format, size, labelButton, url
}) => {
  const convertFromKBToMB = (sizeUnit) => {
    const megaByteUnit = 10 ** 6;
    if (sizeUnit >= megaByteUnit) {
      return (sizeUnit / megaByteUnit).toFixed(2);
    }
    return Math.floor(sizeUnit / 1000);
  };
  const setUnitLength = (sizeUnit) => {
    const megaByteUnit = 10 ** 6;
    return sizeUnit > megaByteUnit ? 'Mb' : 'kb';
  };

  const convertedSize = convertFromKBToMB(size);
  const convertedFormat = setUnitLength(size);

  const formatContent = `${format} / ${convertedSize} ${convertedFormat}`;
  return (
    <div className="download__content">
      <p className="download__paragraph">
        <span className="download__title paragraph">{title}</span>
        <span className="download__type-size small">{formatContent}</span>
      </p>
      <AnchorLink
        kind="small"
        label={labelButton}
        color="white"
        url={url}
        target
      />
    </div>
  );
};

export default Download;
