import React from 'react';
import Link from '../TransitionLink';
import Container from '../Container';

const NavigationThirdLevel = ({
  labelParent, parentPathname, menuItems, pageSlug
}) => {
  return (
    <Container className="navigation-third-level">
      {labelParent && <h4 className="navigation-third-level__title heading-xs">{labelParent.title}</h4>}
      <ul className="navigation-third-level__list">
        {
          menuItems && menuItems.map((menuItem) => {
            const { id, title, slug } = menuItem;
            return (
              <li
                className="navigation-third-level__list-item"
                key={id}
              >
                <Link
                  to={`/${parentPathname}/${slug}/`}
                  className={`navigation-third-level__link caption ${pageSlug === menuItem.slug ? 'navigation-third-level__link--active' : ''}`}
                >
                  {title}
                </Link>
              </li>
            );
          })
        }
      </ul>
    </Container>
  );
};

export default NavigationThirdLevel;
