import React, { useState, useRef, useLayoutEffect } from 'react';
import Img from 'gatsby-image';
import gsap from 'gsap';
import Container from '../Container';

const VideoEmbed = ({
  marginTop,
  marginBottom,
  image,
  url,
  title,
  hasDefaultLayout
}) => {
  const [showIframe, setShowIframe] = useState(false);
  const previewRef = useRef(null);
  const videoRef = useRef(null);
  useLayoutEffect(() => {
    const timeline = gsap.timeline();
    if (showIframe) {
      timeline
        .to(previewRef.current, { opacity: 0, duration: 0.3, ease: 'out' })
        .to(previewRef.current, { display: 'none' }, 0.3)
        .to(videoRef.current, { display: 'block' })
        .to(videoRef.current, { opacity: 1, duration: 0.2, ease: 'in' });
    }
  }, [showIframe]);

  return (
    <Container
      className="video-embed"
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      <div className={`${hasDefaultLayout ? '' : 'video-embed__wrapper'}`}>
        <div className="video-embed__containers">
          <div ref={previewRef} className={`video-embed__container video-embed__container--preview ${!showIframe ? 'video-embed__container--active' : ''}`}>
            <Img
              fluid={{
                ...image.fluid,
                aspectRatio: 16 / 9
              }}
              alt={image.alt}
              className="video-embed__image"
            />
            <button
              type="button"
              onClick={() => setShowIframe(true)}
              className="video-embed__btn label"
            >
              Play
            </button>
          </div>
          <div ref={videoRef} className={`video-embed__container video-embed__container--video ${showIframe ? 'video-embed__container--active' : ''}`}>
            <iframe
              title={title}
              width="100%"
              height="auto"
              src={`https://www.youtube.com/embed/${url}?autoplay=${showIframe ? 1 : 0}&color=white&modestbranding=1`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default VideoEmbed;
