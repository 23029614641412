/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import ThankYou from '../components/ThankYou';
import Container from '../components/Container';

const title = 'Grazie mille, abbiamo ricevuto i tuoi dati';

/*
const description = 'Compilando questo modulo, avrai espresso il tuo desiderio
di far parte della grande famiglia di Volontarie e Volontari dell\'UNICEF Italia,
attiva in ogni angolo del nostro Paese per realizzare iniziative -
nazionali e locali - a beneficio dei bambini in tutto il mondo.';
*/

const withAccordionGroup = (WrappedComponent) => () => {
  const [showThankYouPage, setShowThankYouPage] = useState(false);
  return (
    <Container>
      { showThankYouPage
        ? (
          <ThankYou
            setShowThankYouPage={setShowThankYouPage}
            description=""
            title={title}
          />
        )
        : <WrappedComponent setShowThankYouPage={setShowThankYouPage} /> }
    </Container>
  );
};

export default withAccordionGroup;
